import React from 'react';
import useModalStyles from './useModalStyles';
import { formatNumberWithLabel, getMeasurementSys } from './utils';
import { useAppSelector } from "../../../../../reduxToolkit/store/hooks";
import { DepthVolume } from "../constants";
import { Measurement } from "../../../../../shared/measurements-converter/measurement-enums/measurement-enums";
import { useTranslation } from 'react-i18next';
import {MeasurementSystem} from "../../../../../shared/data/types/UserTypes";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
interface TableSectionProps {
    data?: Record<string, any>[]; // Array of objects with string keys and any value
    generateCSV: () => string; // CSV generation function passed as a prop
}

const TableSection: React.FC<TableSectionProps> = ({ data, generateCSV }) => {
    const classes = useModalStyles();
    const measurement_system = useAppSelector(
        (state) => state.rootReducer.userState.measurementSystem,
    );
    const userCountry = useAppSelector((state) => state.rootReducer.userState.country);
    const measurementSys = getMeasurementSys(measurement_system);
    const depth_volume = useAppSelector(
        (state) => state.rootReducer.userState.depth_volume,
    );
    const measurement = depth_volume === DepthVolume.volume ?
        Measurement.VOLUME :
        Measurement.ETA;

    const columns = data && data.length > 0 ? Object.keys(data[0]) : [];
    const currentBlock = useAppSelector((state) => state.rootReducer.blockState.currentBlock);
    const formatDateTime = (dateString: string, isMetric: boolean) => {
        const date = dayjs.utc(dateString); // Parse date string in UTC
        const time = date.format('HH:mm');
        const formattedDate = isMetric
            ? date.format('DD.MM.YY') // Metric format: DD.MM.YY
            : date.format('MM.DD.YY'); // Imperial format: MM.DD.YY

        return (
            <div className={classes.dateTimeCell}>
                <span className={classes.dateTimeTime}>{time}</span>
                <span className={classes.dateTimeDate}>{formattedDate}</span>
            </div>
        );
    };
    const { t } = useTranslation();

    // Map column names to translation keys
    const columnTranslations: { [key: string]: string } = {
        'Date': t('blockDataWidget.date'),
        'Amount': t('blockDataWidget.amount'),
        'Start': t('blockDataWidget.start'),
        'End': t('blockDataWidget.end'),
        'Time': t('blockDataWidget.time'),
        'Actual Consumption': t('blockDataWidget.actual_consumption'),
    };

    return (
        <div className={classes.tableContainer}>
            <table className={classes.table}>
                <thead>
                <tr>
                    {columns.map((column, index) => (
                        <th key={index}>{columnTranslations[column] || column}</th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {data?.map((item, rowIndex) => (
                    <tr key={rowIndex}>
                        {columns.map((column, colIndex) => {
                            const value = item[column];
                            const formattedValue = (column === 'Start' || column === 'End') && typeof value === 'string'
                                ? formatDateTime(value,measurement_system === MeasurementSystem.Metric)
                                : typeof value === 'number'
                                    ? formatNumberWithLabel(value, userCountry, measurementSys, measurement, 2, currentBlock?.area)
                                    : value;
                            return <td key={colIndex}>{formattedValue}</td>;
                        })}
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default TableSection;