// src/shared/components/Map/Map.tsx

import { useTranslation } from 'react-i18next';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import {
    Autocomplete,
    GoogleMap,
    Marker,
    InfoBox,
    MarkerClusterer,
    useJsApiLoader,
    OverlayView,
} from '@react-google-maps/api';
import { MapProps } from 'src/shared/data/types/mapTypes';
import notifyService from 'src/shared/notification/notification.service';
import messageTypes from 'src/consts/messages';
import IconWithToolTip from 'src/shared/components/IconWithToolTip';
import Container from '@mui/material/Container';
import { Button } from 'antd';
import { MapTypes } from 'src/shared/constants/enums';
import BlockPolygons from '../../../pages/FieldsPage/BlockPolygons';
import TextForm from '../../../pages/FieldsPage/FarmManagement/FarmModal/AddFarmModal/TextForm';
import { mapContainerStyle, toolTipContainerStyle } from './useStyles';
import './map.scss';
import useStyles from '../../../pages/FieldsPage/FarmManagement/FarmModal/useStyles';
import NavigateIcon from 'src/shared/components/Icon/NavigateIcon';
import CopyIcon from 'src/shared/components/Icon/CopyIcon';
import { IconFile } from '../../../assets/Icons/blockData';
import { useAppSelector } from '../../../reduxToolkit/store/hooks';
import Lottie from 'lottie-react';
import Sprinkler from '../../../assets/animations/Sprinkler.json';

function Map(mapProps: MapProps): JSX.Element {
    const {
        mapCenter,
        defaultCenter,
        blocksPolygons,
        mapZoom,
        isPointCoordinatesAvailable,
        onPointClick,
        onPaste,
        markersPoints,
        autocomplete,
        defaultIcon,
        farmFields,
        mapTypeId,
        drawModeIndicator,
    } = mapProps;

    const classes = useStyles();
    const { t } = useTranslation();
    const realtimeIrrigation = useAppSelector(
        (state) => state.rootReducer.flowDataState.realtime_irrigation,
    );

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? '',
        preventGoogleFontsLoading: true,
        libraries: ['drawing', 'geometry', 'places'],
        language: 'en',
        region: 'us',
    });

    const mapRef = useRef<google.maps.Map | null>(null);
    const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);

    const [position, setPosition] = useState<google.maps.LatLngLiteral>(mapCenter);
    const [markers, setMarkers] = useState<any[]>([]);
    const [zoom, setZoom] = useState<number>(mapZoom || 8); // Default zoom level
    const [openInfoBox, setOpenInfoBox] = useState<string | undefined>(undefined);
    const [pastedLocation, setPastedLocation] = useState<string>('');

    // Handle Enter key in the search input
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            if (onPaste) onPaste(pastedLocation);
        }
    };

    // Handle map load and set up zoom listener
    const handleLoad = (map: google.maps.Map): void => {
        mapRef.current = map;
        map.addListener('zoom_changed', () => {
            if (mapRef.current) {
                const newZoom = mapRef.current.getZoom();
                setZoom(newZoom ? newZoom : 8);
            }
        });
    };

    // Update center position after dragging
    const handleCenter = (): void => {
        if (!mapRef.current) return;
        const newPos = mapRef.current.getCenter()?.toJSON();
        if (newPos) {
            setPosition(newPos);
        }
    };

    // Update position when mapCenter prop changes
    useEffect(() => {
        setPosition(mapCenter);
    }, [mapCenter]);

    // Update markers when markersPoints prop changes
    useEffect(() => {
        if (markersPoints) {
            setMarkers(markersPoints);
        } else {
            setMarkers([]);
        }
    }, [markersPoints]);

    // Update zoom when mapZoom prop changes
    useEffect(() => {
        setZoom(mapZoom);
    }, [mapZoom]);

    // Handle autocomplete load
    const onLoadAutocomplete = (autocompleteInstance: google.maps.places.Autocomplete): void => {
        autocompleteRef.current = autocompleteInstance;
    };

    // Handle copy actions
    const handleOnClick = (
        details: any,
        event: React.MouseEvent<HTMLSpanElement> | React.KeyboardEvent<HTMLSpanElement>,
    ) => {
        if (
            event.type === 'click' ||
            (event.type === 'keydown' &&
                (event as React.KeyboardEvent<HTMLSpanElement>).key === 'Enter')
        ) {
            onCopy(details);
        }
    };

    // Copy text to clipboard based on type
    const onCopy = (details: any) => {
        let textToCopy = '';
        switch (details.type) {
            case 1: // IMEI number
                textToCopy = details.details;
                notifyService(t('map.notifyCopyImeiNumberSuccess'), messageTypes.success);
                break;
            case 2: // Coordinate
                textToCopy = `${details.details.lat}, ${details.details.lng}`;
                notifyService(t('map.notifyCopyCoordinateSuccess'), messageTypes.success);
                break;
            case 3: // Navigation
                textToCopy = `https://www.google.com/maps/dir/?api=1&destination=${details.details.lat},${details.details.lng}`;
                notifyService(t('map.notifyNavigateToLocationSuccess'), messageTypes.success);
                break;
            default:
                break;
        }

        navigator.clipboard
            .writeText(textToCopy)
            .then(() => {})
            .catch((err) => {
                console.error('Failed to copy text: ', err);
            });
    };

    // Handle place changed in autocomplete
    const onPlaceChanged = (): void => {
        if (autocompleteRef.current !== null) {
            const place = autocompleteRef.current.getPlace();
            if (place.name !== '' && place.geometry?.location) {
                const newPosition = {
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng(),
                };
                setPosition(newPosition);
                if (onPointClick) {
                    onPointClick({
                        latLng: place.geometry.location,
                    });
                }
                if (onPaste) onPaste({ latLng: place.geometry.location });
                setZoom(15);
            }
        } else {
            console.warn(t('addFarmModal.loadedWarning'));
        }
    };

    // Render Autocomplete input
    const renderAutoComplete = (): JSX.Element | null =>
        autocomplete ? (
            <article className={`flex ${classes.farmTitleSearch}`}>
                <TextForm data={farmFields.titleField} />
                <Autocomplete onLoad={onLoadAutocomplete} onPlaceChanged={onPlaceChanged}>
                    <div className={`flex-column ${classes.searchLocation}`}>
                        <span id="timezone">{t('addFarmModal.searchLocation')}</span>
                        <input
                            type="text"
                            id="farm-location"
                            placeholder={`${t('addFarmModal.searchFarmLocationPlaceholder')}`}
                            className={classes.farmLocationInput}
                            onChange={
                                onPaste
                                    ? (e) => {
                                        setPastedLocation(e.target.value);
                                        onPaste(e.target.value);
                                    }
                                    : undefined
                            }
                            onKeyDown={handleKeyDown}
                            data-hj-allow=""
                        />
                    </div>
                </Autocomplete>
            </article>
        ) : null;

    // Function to get icon URL for static markers
    const getIconUrl = useCallback(
        (marker: any): string => {
            if (marker.isFlowSensor) {
                if (realtimeIrrigation.includes(marker.imei)) {
                    // Return a static "on" icon if irrigation is on
                    return IconFile['irrigation_on'];
                } else {
                    return IconFile['irrigation_off'];
                }
            }
            return IconFile['sap'];
        },
        [realtimeIrrigation],
    );

    // Render InfoBox content
    const renderInfoBox = (marker: any) => (
        <div className="map__info-box">
            <Button className="map__info-box-btn" onClick={() => setOpenInfoBox(undefined)}>
                X
            </Button>
            <div className="map__info-box-center-row">
                <CopyIcon
                    onClick={(event) =>
                        handleOnClick({ details: marker.details, type: 1 }, event)
                    }
                    onKeyDown={(event) =>
                        handleOnClick({ details: marker.details, type: 1 }, event)
                    }
                    tabIndex={0}
                />
                <span
                    role="button"
                    id="copy-imei-number"
                    onClick={(event) =>
                        handleOnClick({ details: marker.details, type: 1 }, event)
                    }
                    onKeyDown={(event) =>
                        handleOnClick({ details: marker.details, type: 1 }, event)
                    }
                    tabIndex={0}
                >
                    {t('map.copyImei')}
                </span>
            </div>
            <div className={'map__info-box-imei-number'}>{marker.details}</div>
            <div className="map__info-box-separator"></div>
            <div className="map__info-box-center-row">
                <CopyIcon
                    onClick={(event) =>
                        handleOnClick(
                            { details: { lat: marker.lat, lng: marker.lng }, type: 2 },
                            event,
                        )
                    }
                    onKeyDown={(event) =>
                        handleOnClick(
                            { details: { lat: marker.lat, lng: marker.lng }, type: 2 },
                            event,
                        )
                    }
                    tabIndex={0}
                />
                <span
                    role="button"
                    id="copy-coordinate"
                    onClick={(event) =>
                        handleOnClick(
                            { details: { lat: marker.lat, lng: marker.lng }, type: 2 },
                            event,
                        )
                    }
                    onKeyDown={(event) =>
                        handleOnClick(
                            { details: { lat: marker.lat, lng: marker.lng }, type: 2 },
                            event,
                        )
                    }
                    tabIndex={0}
                >
                    {t('map.copyCoordinate')}
                </span>
            </div>
            <div className="map__info-box-separator"></div>
            <div className="map__info-box-center-row">
                <NavigateIcon
                    onClick={(event) => {
                        event.preventDefault();
                        handleOnClick(
                            { details: { lat: marker.lat, lng: marker.lng }, type: 3 },
                            event,
                        );
                        window.open(
                            `https://www.google.com/maps/search/?api=1&query=${marker.lat},${marker.lng}&zoom=17&layer=satellite`,
                            '_blank',
                        );
                    }}
                    onKeyDown={(event) => {
                        handleOnClick(
                            { details: { lat: marker.lat, lng: marker.lng }, type: 3 },
                            event,
                        );
                    }}
                    tabIndex={0}
                />
                <a
                    role="button"
                    id="navigate-to-location"
                    href={`https://www.google.com/maps/dir/?api=1&destination=${marker.lat},${marker.lng}`}
                    target="_blank"
                    rel="noreferrer noopener"
                    onClick={(event) => {
                        event.preventDefault();
                        handleOnClick(
                            { details: { lat: marker.lat, lng: marker.lng }, type: 3 },
                            event,
                        );
                        window.open(
                            `https://www.google.com/maps/search/?api=1&query=${marker.lat},${marker.lng}&zoom=17&layer=satellite`,
                            '_blank',
                        );
                    }}
                    onKeyDown={(event) => {
                        handleOnClick(
                            { details: { lat: marker.lat, lng: marker.lng }, type: 3 },
                            event,
                        );
                    }}
                    tabIndex={0}
                >
                    {t('map.navigateToLocation')}
                </a>
            </div>
        </div>
    );

    return isLoaded ? (
        <>
            {renderAutoComplete()}
            <GoogleMap
                id="map"
                mapContainerStyle={mapContainerStyle}
                center={position || defaultCenter}
                zoom={zoom}
                options={{
                    zoomControlOptions: {
                        position: google.maps.ControlPosition.BOTTOM_RIGHT,
                    },
                    streetViewControlOptions: {
                        position: google.maps.ControlPosition.LEFT_BOTTOM,
                    },
                    mapTypeControl: false,
                    mapTypeId: mapTypeId || MapTypes.SATELLITE,
                    mapTypeControlOptions: {
                        mapTypeIds: [
                            google.maps.MapTypeId.ROADMAP,
                            google.maps.MapTypeId.SATELLITE,
                            google.maps.MapTypeId.HYBRID,
                        ],
                        position: google.maps.ControlPosition.LEFT_CENTER,
                    },
                    rotateControl: false,
                }}
                onLoad={handleLoad}
                onDragEnd={handleCenter}
                onClick={(ev) => {
                    if (isPointCoordinatesAvailable && onPointClick) {
                        onPointClick(ev);
                    }
                }}
            >
                {/* Render Polygons if available */}
                {blocksPolygons && (
                    <BlockPolygons
                        blocksPolygons={blocksPolygons}
                        drawModeIndicator={drawModeIndicator}
                    />
                )}

                {markers.length > 0 && (
                    <MarkerClusterer
                        options={{
                            minimumClusterSize: 3,
                            maxZoom: 10,
                        }}
                    >
                        {(clusterer) => (
                            <>
                                {markers.map((marker: any) => {
                                    const isAnimated =
                                        marker.isFlowSensor &&
                                        realtimeIrrigation.includes(marker.imei);

                                    // Convert lat/lng to number if they're not
                                    const latNum = +marker.lat;
                                    const lngNum = +marker.lng;
                                    const markerKey = `${latNum}_${lngNum}_${marker.imei}`;

                                    if (isAnimated) {
                                        return (
                                            <OverlayView
                                                key={markerKey}
                                                position={new google.maps.LatLng(latNum, lngNum)}
                                                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                                getPixelPositionOffset={(width, height) => ({
                                                    x: -width / 2,
                                                    y: -height,
                                                })}
                                            >
                                                <div style={{ cursor: 'pointer' }}>
                                                    {/* Sprinkler icon */}
                                                    <div onClick={() => setOpenInfoBox(markerKey)}>
                                                        <Lottie
                                                            animationData={Sprinkler}
                                                            loop
                                                            style={{ width: 27, height: 27 }}
                                                        />
                                                    </div>
                                                    {/* InfoBox for the Lottie marker */}
                                                    {openInfoBox === markerKey && marker.details && (
                                                        <InfoBox
                                                            position={new google.maps.LatLng(
                                                                latNum,
                                                                lngNum,
                                                            )}
                                                            onCloseClick={() =>
                                                                setOpenInfoBox(undefined)
                                                            }
                                                            options={{
                                                                closeBoxURL: '',
                                                                enableEventPropagation: true,
                                                            }}
                                                        >
                                                            {renderInfoBox({
                                                                ...marker,
                                                                lat: latNum,
                                                                lng: lngNum,
                                                            })}
                                                        </InfoBox>
                                                    )}
                                                </div>
                                            </OverlayView>
                                        );
                                    } else {
                                        return (
                                            <Marker
                                                clusterer={clusterer}
                                                key={markerKey}
                                                options={{ optimized: false }}
                                                icon={{
                                                    url: getIconUrl(marker),
                                                    scaledSize: new google.maps.Size(47, 47),
                                                    // Make sure the anchor is updated to keep the marker pinned in place
                                                    anchor: new google.maps.Point(23.5, 23.5),
                                                }}
                                                onClick={() => setOpenInfoBox(markerKey)}
                                                position={new google.maps.LatLng(latNum, lngNum)}
                                            >
                                                {openInfoBox === markerKey && marker.details && (
                                                    <InfoBox
                                                        position={new google.maps.LatLng(
                                                            latNum,
                                                            lngNum,
                                                        )}
                                                        onCloseClick={() =>
                                                            setOpenInfoBox(undefined)
                                                        }
                                                        options={{
                                                            closeBoxURL: '',
                                                            enableEventPropagation: true,
                                                        }}
                                                    >
                                                        {renderInfoBox({
                                                            ...marker,
                                                            lat: latNum,
                                                            lng: lngNum,
                                                        })}
                                                    </InfoBox>
                                                )}
                                            </Marker>
                                        );
                                    }
                                })}
                            </>
                        )}
                    </MarkerClusterer>
                )}

                {/* Tooltip with Terms of Use */}
                <Container style={toolTipContainerStyle}>
                    <IconWithToolTip
                        color="#fff"
                        text={
                            <a
                                target="_blank"
                                rel="noreferrer noopener"
                                href="https://www.google.com/intl/en-US_US/help/terms_maps/"
                                style={{ textDecoration: 'none', color: '#676879d9' }}
                            >
                                {t('addFarmModal.termsOfUse')}
                            </a>
                        }
                    />
                </Container>
            </GoogleMap>
        </>
    ) : (
        <p>{t('addFarmModal.loadingMap')}</p>
    );
}

export default Map;