import { useTranslation } from 'react-i18next';
import {
  Button,
  DatePicker,
  Select,
  Checkbox,
  Radio,
  Space,
  Typography,
} from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { RadioChangeEvent } from 'antd';
import { saveAs } from 'file-saver';
import moment from 'moment';
import formatDate from 'src/shared/helpers/HelperFunctions';
import localeEN from 'antd/es/date-picker/locale/en_US';
import localeES from 'antd/es/date-picker/locale/es_ES';
import localTR from 'antd/es/date-picker/locale/tr_TR';
import dayjs from 'dayjs';
import useStyles from './useStyles';
import { useAppSelector } from '../../../../reduxToolkit/store/hooks';
import farmAdapter from '../../../../reduxToolkit/adapters/FarmsAdapter';
import SmallProgressBar from '../../../../shared/components/SmallProgressBar';
import Download from '../../../../assets/Icons/download/download';
import mainTheme from '../../../../theme/mainTheme';
import toroTheme from '../../../../theme/toroTheme';
import 'dayjs/locale/es';

const { RangePicker } = DatePicker;

const getLocale = (lang: string): any => {
  switch (lang) {
    case 'es-ES': {
      return localeES;
    }
    case 'tr-TR': {
      return localTR;
    }
    case 'en-US':
    default: {
      return localeEN;
    }
  }
};

const dateFormat = 'DD MMM YYYY';

const getContentType = (type: string): string => {
  switch (type) {
    case 'CSV': {
      return 'application/zip; charset=utf-8';
    }
    case 'XLS': {
      return 'text/xlsx; charset=utf-8';
    }
    default: {
      return 'text/json';
    }
  }
};

const returnExtension = (type: string): string => {
  switch (type) {
    case 'CSV': {
      return 'zip';
    }
    case 'XLS': {
      return 'xlsx';
    }
    default: {
      return 'json';
    }
  }
};

function ResearcherReportsManagement(props: any): JSX.Element {
  const classes = useStyles();
  const isToroAccount = useAppSelector(
    (state) => state.rootReducer.accountState.isToroAccount,
  );
  const selectedGrower = useAppSelector(
    (state) => state.rootReducer.growerState.selectedGrower,
  );
  const { currentBlock } = useAppSelector(
    (state) => state.rootReducer.blockState,
  );
  const forestUrbanCrop =
    currentBlock?.cropType === 'Urban Tree' ||
    currentBlock?.cropType === 'Forest Tree';
  const [selectedFarms, setSelectedFarms] = useState<number[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [reportTypeList, setReportTypeList] = useState<string[]>([]);
  const [checkAll, setCheckAll] = useState(false);
  const [hoverOnDownload, setHoverOnDownload] = useState(false);
  const [exportBtnClicked, setExportBtnClicked] = useState<boolean>(false);
  const [type, setType] = useState<string>('XLS');
  const [timeType, setTimeType] = useState<string>('hourly');
  const [dateRange, setDateRange] = useState<any>({
    startDate: undefined,
    endDate: undefined,
  });
  const { t } = useTranslation();
  const selectedMeasurementSystem = useAppSelector(
    (state) => state.rootReducer.userState.measurementSystem,
  );
  const theme = !isToroAccount ? mainTheme : toroTheme;
  const grower = useAppSelector((state) => {
    return state.rootReducer.GrowerFarmsState.currentGrower;
  });

  const plainOptions = useMemo(
    () =>
      forestUrbanCrop
        ? ['SFD', 'VPD', 'Eto']
        : [`${t('researchReports.sapFlow')}`, 'SFD', 'VPD', 'Eto'],
    [t],
  );

  const save = async (): Promise<void> => {
    if (selectedFarms && selectedFarms.length > 0) {
      try {
        setLoading(true);
        const { data } = await farmAdapter.getReport({
          farmIds: selectedFarms,
          fileType: type,
          timeType: timeType,
          dataTypes: reportTypeList,
          ...dateRange,
          mSys: selectedMeasurementSystem,
        });
        const d = new Date();
        const blob = new Blob([data], { type: getContentType(type) });
        const ts = formatDate(d);
        saveAs(
          blob,
          `${grower?.title}-${timeType}-${t(
            'researchReports.downloadNameLabel',
          )}-${ts}.${returnExtension(type)}`,
        );
        setLoading(false);
        setExportBtnClicked(false);
        window.gtag('event', 'research_report_download_name', {
          file_name: `${grower?.title}-${timeType}-${t(
            'researchReports.downloadNameLabel',
          )}-${ts}.${returnExtension(type)}`,
        });
        window.gtag('event', 'research_report_download_extension', {
          file_extension: type,
        });
      } catch (ex) {
        setLoading(false);
      }
    }
  };

  const onButtonClicked = (): void => {
    setExportBtnClicked(true);
    if (selectedFarms && selectedFarms.length > 0) {
      void save();
    }
  };

  const handleChangeSelection = (items: number[]): void => {
    setSelectedFarms(items);
  };

  const onChangeSelect = (item: any): void => {
    const items: string[] = reportTypeList.slice();
    if (item?.target?.value) {
      if (!reportTypeList.includes(item?.target?.value)) {
        items.push(item?.target?.value);
      } else {
        items.splice(reportTypeList.indexOf(item?.target?.value), 1);
      }
      setReportTypeList(items);
    }
    setCheckAll(items.length === plainOptions.length);
  };

  const onChangeType = (e: RadioChangeEvent): void => {
    setType(e.target.value);
  };

  const onChangeTimeType = (e: RadioChangeEvent): void => {
    setTimeType(e.target.value);
  };

  const onCheckAllChange = useCallback(
    (e: any): void => {
      setReportTypeList(e.target.checked ? plainOptions : []);
      setCheckAll(e.target.checked);
    },
    [plainOptions],
  );

  const handleDateRangeChange = (e: any): void => {
    setDateRange({
      startDate: e ? e[0].toDate() : null,
      endDate: e ? e[1].toDate() : null,
    });
  };

  useEffect(() => {
    onCheckAllChange({ target: { checked: true } });
  }, [onCheckAllChange]);

  useEffect(() => {
    if (grower?.farms) {
      handleChangeSelection(grower?.farms.map((f: any) => f.id));
    }
  }, [grower?.farms]);

  const language: any = localStorage.getItem('i18nextLng');

  const changeLanguage = (lang: string): void => {
    switch (lang) {
      case 'es-ES': {
        dayjs.locale('es');
        break;
      }
      case 'tr-TR': {
        dayjs.locale('tr');
        break;
      }
      case 'en-US':
      default: {
        dayjs.locale('en-US');
      }
    }
  };

  changeLanguage(language);

  return (
    <div
      className={`flex-column align-center justify-between ${classes.reportsModal}`}
      id="reports-modal">
      <div className={classes.header}>
        {selectedGrower?.title} {`${t('researchReports.modalTitle')}`}
      </div>
      <div className={classes.filterContainer}>
        <div className={classes.filterDatePicker}>
          <RangePicker
            className={classes.rangePicker}
            onChange={handleDateRangeChange}
            locale={getLocale(language)}
            format={dateFormat}
            disabledDate={(current: dayjs.Dayjs) => current && current.isAfter(dayjs())}
          />
          <div className={classes.filterContainer}>
            <div className={classes.filterSelect}>
              <Checkbox
                onChange={onCheckAllChange}
                checked={checkAll}
                className={classes.selectAllTypes}>
                {`${t('researchReports.selectAll')}`}
              </Checkbox>
              <div
                className="ant-checkbox-group"
                style={{ display: 'inline-block', marginRight: 10 }}>
                {plainOptions.map((label) => (
                  <div key={label}>
                    <Checkbox
                      value={label}
                      checked={reportTypeList.includes(label)}
                      onChange={onChangeSelect}
                      className={classes.checkbox}>
                      {label}
                    </Checkbox>
                  </div>
                ))}
              </div>
              <br />
              <Space direction="vertical">
                <Radio.Group onChange={onChangeTimeType} value={timeType}>
                  <Radio value="hourly">{`${t(
                    'researchReports.hourly',
                  )}`}</Radio>
                  <Radio value="daily">{`${t('researchReports.daily')}`}</Radio>
                </Radio.Group>
              </Space>
            </div>
            <div className={classes.filterSelect}>
              <Space direction="vertical">
                <Radio.Group onChange={onChangeType} value={type}>
                  <Radio value="XLS">XLS</Radio>
                  <Radio value="CSV">CSV</Radio>
                </Radio.Group>
              </Space>
            </div>
          </div>
        </div>
        <div className={classes.filterSelectFarms}>
          <Select
            options={grower?.farms.map((item: any) => ({
              value: item.id,
              label: item.title,
            }))}
            mode="multiple"
            value={selectedFarms}
            data-hj-allow
            style={{ width: '100%' }}
            className={classes.multiSelect}
            placeholder={`${t('researchReports.placeholder')}`}
            onChange={handleChangeSelection}
          />
        </div>
      </div>
      <div
        className={`flex-column align-center justify-between ${classes.btnContainer}`}>
        {selectedFarms.length > 0 && exportBtnClicked ? (
          <Typography className={classes.warning}>{`${t(
            'researchReports.delayWarning',
          )}`}</Typography>
        ) : null}
        <Button
          id="export-research-btn"
          className={`ant-btn-custom ${
            isToroAccount ? classes.toroModalExportBtn : classes.modalExportBtn
          }`}
          disabled={
            !selectedFarms ||
            selectedFarms.length === 0 ||
            loading ||
            reportTypeList.length === 0
          }
          onClick={onButtonClicked}
          onMouseEnter={() => {
            setHoverOnDownload(true);
          }}
          onMouseLeave={() => {
            setHoverOnDownload(false);
          }}>
          <span>{`${t('researchReports.export')}`}</span>
          <span
            className={hoverOnDownload ? classes.downloadIcon : ''}
            style={{ width: '1rem', marginLeft: '0.5rem' }}>
            <Download color={theme.palette.accent.main} />
          </span>
        </Button>
        {loading && (
          <div className={classes.loaderWrapper}>
            <div className={classes.loaderContainer}>
              <SmallProgressBar />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ResearcherReportsManagement;
