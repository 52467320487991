// src/components/BlockList/useStyles.ts

import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles(
    (theme: Theme) => ({
        root: {
            width: '100%',
            height: '100%', // Ensure the root takes the full available height
            display: 'flex',
            flexDirection: 'column',
        },
        searchBlockFarmContainer: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            paddingBottom: theme.paddings.xs,
        },
        searchBlockText: {
            ...theme.typography.title3,
            color: theme.palette.onBorder.main,
            '@media (min-width: 767.9px) and (max-height: 700px)': {
                fontSize: '15px',
            },
        },
        addBlockContainer: {
            alignItems: 'center',
        },
        addBlockBtn: {
            alignItems: 'center',
            paddingRight: theme.paddings.m,
        },
        searchBlockContainer: {
            flex: 'none', // Prevent the search container from growing or shrinking
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        listContainer: {
            flex: '1',
            overflowY: 'auto',
            width: '100%',
            padding: '0 4px', // Add horizontal padding to the container
            '&::-webkit-scrollbar': {
                width: '2px',
            },
            '&::-webkit-scrollbar-track': {
                background: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.silver.main,
                borderRadius: '60px',
                scrollbarWidth: '5px', // For Firefox
            },
            [theme.breakpoints.down('sm')]: {
                maxHeight: '150px',
            },
        },
        blockListContainer: {
            // For Firefox
        },
        // New wrapper for Accordion to handle borderRadius and boxShadow
        accordionWrapper: {
            borderRadius: theme.borderRadius.s,
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            margin: '8px 0',
            overflow: 'visible', // Allow boxShadow to be visible
            backgroundColor: 'transparent', // Ensure wrapper background doesn't interfere
            '@media (min-width: 767.9px) and (max-height: 700px)': {
                margin: '4px 0',
            },
        },
        accordion: {
            backgroundColor: theme.palette.surfaceWhite.main,
            borderRadius: theme.borderRadius.s, // Ensure borderRadius
            overflow: 'hidden', // Hide internal overflow to respect borderRadius
            border: `1px solid transparent`,
            '&:before': {
                display: 'none',
            },
            '&:hover:not(.Mui-expanded)': {
                borderBottomColor: theme.palette.primary.main,
                // No need to set borderRadius here as it's handled by the wrapper
            },
            '&.Mui-expanded': {
                boxShadow: 'none', // Remove the shadow when expanded to avoid duplication
                borderColor: theme.palette.primary.main, // Set the border color when expanded
                // borderRadius remains set by the wrapper
            },
        },
        accordionSummary: {
            height: '88px',
            cursor: 'pointer', // Default cursor is pointer
            minHeight: 'unset !important',
            paddingInline: theme.paddings.l,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            '& .MuiAccordionSummary-content': {
                margin: 0,
                alignItems: 'center',
                width: '100%',
            },
            '& .MuiAccordionSummary-expandIconWrapper': {
                transform: 'none !important',
            },
            '&.Mui-expanded': {
                cursor: 'default', // Cursor changes to default when expanded
            },
            '@media (max-width: 767.9px)': {
                height: '75px',
            },
            '@media (min-width: 767.9px) and (max-height: 700px)': {
                height: '70px',
            },
        },

        // **New Class for Reduced Height When Expanded and Has Irrigation Image**
        accordionSummaryExpandedIrrigation: {
            paddingTop: theme.paddings.l,
            height: '50px',
            '@media (max-width: 767.9px)': {
                height: '35px', // Adjust if needed for smaller screens
            },
            '@media (min-width: 767.9px) and (max-height: 700px)': {
                height: '35px', // Adjust if needed for specific screen sizes
            },
        },

        summaryContent: {
            margin: 0,
            alignItems: 'center',
            width: '100%',
        },
        summaryText: {
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'left',
        },
        heading: {
            ...theme.typography.bodyMedium,
            '@media (min-width: 767.9px) and (max-height: 700px)': {
                fontSize: '14px',
            },
        },
        headingSelected: {
            color: theme.palette.primary.main,
            '@media (min-width: 767.9px) and (max-height: 700px)': {
                fontSize: '14px',
            },
        },
        subheading: {
            ...theme.typography.bodyRegular,
            color: theme.palette.text.secondary,
        },
        accordionDetails: {
            paddingInline: theme.paddings.xl,
            paddingBottom: theme.paddings.s,
        },
        selectedAccordion: {
            borderColor: theme.palette.primary.main,
        },
        cropTypeContainer: {
            display: 'flex',
            alignItems: 'center', // Align items vertically in the center
            marginTop: theme.paddings.xs,
            gap: theme.paddings.s,
        },
        cropType: {
            ...theme.typography.bodyRegular,
            lineHeight: '0px',
            color: theme.palette.text.secondary,
            '@media (min-width: 767.9px) and (max-height: 700px)': {
                fontSize: '12px',
            },
        },
    }),
    { name: 'BlockList' },
);