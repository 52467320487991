// src/components/BlockList/BlockListCard.tsx

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BlockCardProps } from 'src/shared/data/types/blockTypes';
import { useUserAccess } from 'src/shared/hooks/useUserAccess';
import deleteIcon from '../../../../assets/Icons/sharedIcons/deleteIcon.svg';
import { useStyles } from './useStyles';
import { useAppDispatch, useAppSelector } from '../../../../reduxToolkit/store/hooks';
import {
    initBlockFields,
    setDeletedBlock,
    setEditedBlockId,
} from '../../../../reduxToolkit/slices/AddOrEditBlockSlice/addOrEditBlock.slice';
import { openModal } from '../../../../reduxToolkit/slices/GrowerFarmsSlice/GrowerFarms.slice';
import { GrowerModal } from '../../../Growers/Grower/Grower';
import toGeoJson from '../../../../shared/mappers/toGeoJson';
import {
    convertAndRound,
    convertLabel,
    systemMeasurement,
} from 'src/shared/measurements-converter/converter';
import { Measurement } from 'src/shared/measurements-converter/measurement-enums/measurement-enums';
import { smallMaxWidth, xSmallMaxHeight } from "../../../../shared/constants/consts";
import { useWindowDimensions } from "../../../../shared/hooks/useWindowDimensions";
import GenericImg from "../../../Growers/Grower/block_data_widget/generic_img";
import GenericIcon from '../../../Growers/Grower/block_data_widget/generic_icon';

function BlockListCard(props: BlockCardProps): JSX.Element {
    const { block, isFromInfo, isSelected, isDeleteEnable } = props;
    const { isAdmin, isGrowerAccess } = useUserAccess();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const classes = useStyles({ isFromInfo: !!isFromInfo });

    const selectedMeasurementSystem = useAppSelector(
        (state) => state.rootReducer.userState.measurementSystem,
    );
    const measurementSys = useMemo(
        () => systemMeasurement(selectedMeasurementSystem),
        [selectedMeasurementSystem],
    );
    const userCountry = useAppSelector(
        (state) => state.rootReducer.userState.country,
    );

    // Selector for realtimeIrrigation as string[]
    const realtimeIrrigation = useAppSelector(
        (state) => state.rootReducer.flowDataState.realtime_irrigation
    ) || []; // Default to empty array

    const convertValue = (value: number | undefined, measurement: Measurement): string => {
        if (value === undefined || value === null) {
            return 'N/A';
        }
        const convertedValue = convertAndRound(
            userCountry,
            measurementSys,
            measurement,
            value,
            2, // number of decimal places
        );
        const unitLabel = convertLabel(
            userCountry,
            measurementSys,
            measurement,
        );
        return `${convertedValue} ${unitLabel}`;
    };

    function onEdit() {
        dispatch(setEditedBlockId(block.id));
        dispatch(
            initBlockFields({
                id: block.id,
                title: block.title,
                cropTypeId: block.cropTypeId,
                cropVarietyId: block.cropVarietyId,
                treeSpacing: block.treeSpacing,
                rowSpacing: block.rowSpacing,
                area: block.area,
                soilTextureId: block.soilTextureId,
                plantingYear: block.plantingYear || new Date().getFullYear(),
                irrigationSystemId: block.irrigationSystemId,
                systemApplicationRate: block.flowRate,
                farmId: block.farmId,
                zonalDataJson: undefined, // Assuming this is handled elsewhere
                sensors: block.sensors ,
                installers: block.installers,
                numberOfIrrigationPipes: block.numberOfIrrigationPipes,
                distanceBetween: block.distanceBetween,
                flowRatio: block.flowRatio,
            })
        );
        dispatch(openModal(GrowerModal.editBlock));
    }

    function onDelete() {
        dispatch(setDeletedBlock(block));
        dispatch(openModal(GrowerModal.deleteBlock));
    }

    function onDownload(): void {
        if (block.polygon) {
            try {
                const geoJson = toGeoJson(JSON.parse(String(block.polygon)));
                const jsonString = `data:text/json;charset=utf-8,${encodeURIComponent(
                    JSON.stringify(geoJson)
                )}`;
                const link = document.createElement('a');
                link.href = jsonString;
                link.download = `${block.title}.json`;
                link.click();
            } catch (error) {
                console.error('Error parsing polygon data:', error);
            }
        }
    }

    const flowRate = block.flowRateBlock
        ? `${convertAndRound(
            userCountry,
            measurementSys,
            Measurement.FLOW_RATE,
            block.flowRateBlock,
            3,
        )} ${convertLabel(
            userCountry,
            measurementSys,
            Measurement.FLOW_RATE,
        )}`
        : 'N/A';
    const { width, height } = useWindowDimensions();

    const zoom = smallMaxWidth < width && xSmallMaxHeight > height;

    // Prepare a list of info items
    const infoItems: { titleKey: string; value: React.ReactNode }[] = [
        { titleKey: 'blockDataWidget.variety', value: block.cropVariety},
        { titleKey: 'blockDataWidget.area', value: convertValue(block.area, Measurement.AREA) },
        { titleKey: 'blockDataWidget.planting_year', value: block.plantingYear },
        { titleKey: 'blockDataWidget.tree_spacing', value: convertValue(block.treeSpacing, Measurement.LENGTH) },
        { titleKey: 'blockDataWidget.row_spacing', value: convertValue(block.rowSpacing, Measurement.LENGTH) },
        { titleKey: 'blockDataWidget.irrigation_system', value: t(`${block.irrigationSystem}`)},
        { titleKey: 'fields.applicationRate', value: flowRate },
    ];

    // Filter out items with null or undefined values
    const filteredInfoItems = infoItems.filter(item => item.value !== null && item.value !== undefined && item.value !== '');

    // Determine if the block has any IMEI in realtimeIrrigation
    const hasIrrigation = block.units
        ? block.units.some((unit) => unit?.imei ? realtimeIrrigation.includes(unit.imei) : false)
        : false;

    return (
        <div className={classes.container}>
            {!isGrowerAccess && isSelected && (
                <div>
                    {hasIrrigation && (
                        <GenericImg
                            name="irrigation_on_block"
                            width={zoom ? 50 : 100}
                            height={zoom ? 30 : 60}
                        />
                    )}
                    <div className={classes.separator} style={{ marginTop: '0px' }}></div>
                    <div
                        className={`flex align-center ${classes.actions}`}
                        style={{ textAlign: 'right' }}
                    >
                        <div className={classes.iconBtn}>
                            <GenericIcon name={'edit'} size={zoom ? 16 : 24} onClick={onEdit} />
                        </div>
                        {isDeleteEnable && (
                            <div className={classes.iconBtn} onClick={onDelete}>
                                <img src={deleteIcon} alt="delete" />
                            </div>
                        )}
                        {isAdmin && (
                            <div id="download-block-json" className={classes.iconBtn}>
                                <GenericIcon name={'download'} size={zoom ? 16 : 24} onClick={onDownload} />
                            </div>
                        )}
                    </div>

                    <div className={classes.separator}></div>
                </div>
            )}

            {/* Header Section */}
            <div className={classes.header}>
                <h2 className={classes.title}>{t('blockDataWidget.block_information')}</h2>
                <div className={classes.blockId}>
                    <div className={classes.infoValue}>
                        {t('blockDataWidget.block_id')} {block.id}
                    </div>
                </div>
            </div>

            {/* Info Grid */}
            <div className={classes.infoGrid}>
                {filteredInfoItems.map((item, index) => (
                    <div key={index} className={classes.infoItem}>
                        <div className={classes.infoTitle}>{t(item.titleKey)}</div>
                        <div className={classes.infoValue}>{item.value}</div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default BlockListCard;