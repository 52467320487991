import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import downloadIcon from 'src/assets/Icons/sharedIcons/downloadIcon.svg';
import toroDownloadIcon from 'src/assets/Icons/toroThemeIcons/toroDownloadBox.svg';
import {
  getGrowerWeeksDownloadReport,
  getGrowerWeeksReport,
} from 'src/reduxToolkit/thunks/grower_reports_thunk';
import { saveAs } from 'file-saver';
import { useAppDispatch, useAppSelector } from 'src/reduxToolkit/store/hooks';
import formatDate from 'src/shared/helpers/HelperFunctions';
import { Typography } from 'antd';
import GrowersReportsTableView from './GrowersReportsTableView/GrowersReportsTableView';
import useStyles from './useStyles';
import { setWeeklyReports } from '../../../../reduxToolkit/slices/GrowerReportsSlice/growerReports.slice';
import { MeasurementSystem } from "../../../../shared/data/types/UserTypes";

// Define month mappings for supported languages
const MONTH_MAPPINGS: { [language: string]: { [abbr: string]: number } } = {
  tr: {
    OCA: 1,  // Ocak (January)
    ŞUB: 2,  // Şubat (February)
    MAR: 3,  // Mart (March)
    NIS: 4,  // Nisan (April)
    MAY: 5,  // Mayıs (May)
    HAZ: 6,  // Haziran (June)
    TEM: 7,  // Temmuz (July)
    AĞU: 8,  // Ağustos (August)
    EYL: 9,  // Eylül (September)
    EKI: 10, // Ekim (October)
    KAS: 11, // Kasım (November)
    ARA: 12, // Aralık (December)
  },
  es: {
    ENE: 1,        // Enero (January)
    FEB: 2,        // Febrero (February)
    MAR: 3,        // Marzo (March)
    ABR: 4,        // Abril (April)
    MAY: 5,        // Mayo (May)
    JUN: 6,        // Junio (June)
    JUL: 7,        // Julio (July)
    AGO: 8,        // Agosto (August)
    SEP: 9,        // Septiembre (September)
    OCT: 10,       // Octubre (October)
    NOV: 11,       // Noviembre (November)
    DIC: 12,       // Diciembre (December)
    ENERO: 1,      // Full month names
    FEBRERO: 2,
    MARZO: 3,
    ABRIL: 4,
    MAYO: 5,
    JUNIO: 6,
    JULIO: 7,
    AGOSTO: 8,
    SEPTIEMBRE: 9,
    OCTUBRE: 10,
    NOVIEMBRE: 11,
    DICIEMBRE: 12,
  },
};

function GrowersReportsManagement(): JSX.Element {
  const [weeklyData, setWeeklyData] = useState<any>({});
  const [weeklyDataLoading, setWeeklyDataLoading] = useState<boolean>(false);
  const { data } = useAppSelector((state) => state.rootReducer.blockDataState);
  const blocksData = data;
  const [weekDataSet, setWeekDataSet] = useState<any>([]);
  const [exportBtnClicked, setExportBtnClicked] = useState<boolean>(false);
  const classes = useStyles();
  const [loadingTableData, setLoadingTableData] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const isToroAccount = useAppSelector(
      (state) => state.rootReducer.accountState.isToroAccount,
  );
  const selectedGrower = useAppSelector(
      (state) => state.rootReducer.growerState.selectedGrower,
  );
  const weekStartDay = selectedGrower?.country === 'Israel' ? 1 : 2;
  const { t, i18n } = useTranslation();
  const CURRENT_LANGUAGE = i18n.language.split('-')[0]; // Extract base language code
  const selectedMeasurementSystem = useAppSelector(
      (state) => state.rootReducer.userState.measurementSystem,
  );
  const dispatch = useAppDispatch();

  const grower = useAppSelector((state) => {
    return state.rootReducer.GrowerFarmsState.currentGrower;
  });

  const measurement_system = useAppSelector(
      (state) => state.rootReducer.userState.measurementSystem,
  );

  const save = async (): Promise<void> => {
    try {
      setLoading(true);
      let { data } = await getGrowerWeeksDownloadReport(
          selectedGrower?.id,
          weekStartDay,
          selectedMeasurementSystem,
      );
      data = new Uint8Array(data);
      const d = new Date();
      const ts = formatDate(d);
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
      saveAs(
          blob,
          `${selectedGrower?.title}_${ts}_${t(
              'growerReports.downloadNameLabel',
          )}.xlsx`,
      );
      setLoading(false);
      window.gtag('event', 'grower_report_download', {
        file_name: `${selectedGrower?.title}_${ts}_${t(
            'growerReports.downloadNameLabel',
        )}.xlsx`,
      });
      setExportBtnClicked(false);
    } catch (ex) {
      console.error('Error in save function:', ex);
      setLoading(false);
    }
  };

  const onDownloadButtonClicked = (e: any): void => {
    e.stopPropagation();
    e.preventDefault();
    setExportBtnClicked(true);
    if (selectedGrower != null) {
      void save();
    }
  };

  useEffect(() => {
    setWeeklyDataLoading(true);
    getGrowerWeeksReport(grower?.id ?? 0, weekStartDay)
        .then((res: any) => {
          if (res.data) {
            dispatch(setWeeklyReports({ weeklyReports: res.data }));
            setWeeklyData(res.data);
          } else {
            console.warn('No data received from getGrowerWeeksReport');
          }
          setWeeklyDataLoading(false);
        })
        .catch((error: any) => {
          console.error('Error fetching grower weeks report:', error);
          setWeeklyDataLoading(false);
        });
  }, [dispatch, grower?.id, weekStartDay]);

  const formatDateTable = (
      date: string | Date,
      measurementSystem: MeasurementSystem,
  ): string => {
    console.log('--- formatDateTable Called ---');
    console.log('Input Date:', date);
    console.log('Measurement System:', measurementSystem);
    console.log('Current Language:', CURRENT_LANGUAGE);

    let d: Date;

    try {
      if (typeof date === 'string') {
        const monthMapping = MONTH_MAPPINGS[CURRENT_LANGUAGE];

        if (!monthMapping) {
          console.warn(`No month mapping defined for language: ${CURRENT_LANGUAGE}`);
          return 'Invalid Date';
        }

        // Expected date format: "DD-MMM-YYYY"
        const parts = date.split('-');
        if (parts.length !== 3) {
          console.warn('Unexpected date format:', date);
          return 'Invalid Date';
        }

        const day = parseInt(parts[0], 10);
        const monthAbbr = parts[1].toUpperCase();
        const year = parseInt(parts[2], 10);

        const month = monthMapping[monthAbbr];
        if (!month) {
          console.warn(`Unrecognized month abbreviation (${parts[1]}) for language: ${CURRENT_LANGUAGE}`);
          return 'Invalid Date';
        }

        // Construct a standardized date string: "YYYY-MM-DD"
        const standardizedDateStr = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
        console.log('Standardized Date String:', standardizedDateStr);

        d = new Date(standardizedDateStr);
        console.log('Parsed Date (from standardized string):', d);
      } else {
        d = date;
        console.log('Parsed Date (from Date object):', d);
      }

      // Check for invalid dates
      if (isNaN(d.getTime())) {
        console.warn('Invalid Date detected:', date);
        return 'Invalid Date';
      }

      const day = String(d.getDate()).padStart(2, '0');
      const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      const year = d.getFullYear();

      let formattedDate: string;
      if (measurementSystem === MeasurementSystem.Imperial) {
        formattedDate = `${month}/${day}/${year}`; // MM/DD/YYYY
      } else {
        formattedDate = `${day}/${month}/${year}`; // DD/MM/YYYY
      }

      console.log('Formatted Date:', formattedDate);
      return formattedDate;
    } catch (error) {
      console.error('Error in formatDateTable:', error);
      return 'Invalid Date';
    }
  };

  useEffect(() => {
    if (!blocksData) return;
    try {
      setLoadingTableData(true);
      if (
          weeklyData &&
          Object.keys(weeklyData).length > 0 &&
          weeklyData.weeks &&
          weeklyData.weeks.length > 0
      ) {
        const weekDataArr: any = [];
        weeklyData.weeks.forEach((weekData: any, weekIdx: number) => {
          const formattedStartDate = formatDateTable(weekData.startDate, measurement_system);
          const formattedEndDate = formatDateTable(weekData.endDate, measurement_system);

          weekDataArr.push({
            startDate: formattedStartDate, // Formatted start date
            endDate: formattedEndDate,     // Formatted end date
            farms: weekData.farms,
            weekIdx: weekIdx,
          });

          console.log(`Formatted Week Data [Index ${weekIdx}]:`, {
            startDate: formattedStartDate,
            endDate: formattedEndDate,
            farms: weekData.farms,
            weekIdx: weekIdx,
          });
        });
        setWeekDataSet(weekDataArr);
        console.log('Final weekDataArr:', weekDataArr);
      } else {
        console.warn('weeklyData is empty or malformed.');
        setExportBtnClicked(false);
        setLoadingTableData(false);
      }
    } catch (ex) {
      console.error(ex);
    } finally {
      setLoadingTableData(false);
      console.log('Finished processing in useEffect.');
    }
  }, [blocksData, weeklyData, measurement_system, CURRENT_LANGUAGE]);

  const renderLoader = (): JSX.Element => {
    return (
        <div className={classes.loaderContainer}>
          <div>
            <div className="lds-ellipsis" id="progress-loader">
              <div />
              <div />
              <div />
              <div />
            </div>
          </div>
        </div>
    );
  };

  const renderLoaderOnDownload = (): JSX.Element => {
    return (
        <div className={classes.loaderContainerOnDownload}>
          <div>
            <div className="lds-ellipsis" id="progress-loader">
              <div />
              <div />
              <div />
              <div />
            </div>
          </div>
        </div>
    );
  };

  const renderReportTable = (): JSX.Element => {
    if (weeklyDataLoading) {
      return <div className={classes.loaderWrapper}>{renderLoader()}</div>;
    }
    if (weeklyData?.weeks && weekDataSet.length > 0) {
      return <GrowersReportsTableView weeklyData={weekDataSet} />;
    }
    return <div>{t('growerReports.noDataWarning')}</div>;
  };

  const renderDownloadReports = (): JSX.Element => (
      <Button
          id="export-grower-report-btn"
          onClick={onDownloadButtonClicked}
          disabled={exportBtnClicked || !(weeklyData?.weeks && weekDataSet.length > 0)}
          className={`ant-btn-custom ${classes.exportAllBtn}`}>
        <span>{t('growerReports.export')}</span>
        <span style={{ width: '1rem', marginLeft: '0.5rem' }}>
        {isToroAccount ? (
            <img
                src={toroDownloadIcon}
                alt="Download Icon"
                style={{ maxWidth: '1rem', maxHeight: '1.2rem' }}
            />
        ) : (
            <img
                src={downloadIcon}
                alt="Download Icon"
                style={{ maxWidth: '1rem', maxHeight: '1.2rem' }}
            />
        )}
      </span>
      </Button>
  );

  return (
      <div
          className={`flex-column align-center justify-between ${classes.reportsModal}`}>
        <div className={classes.header}>
          {selectedGrower?.title} {t('growerReports.modalTitle')}
        </div>
        {renderReportTable()}
        <div className={`flex-column align-center ${classes.btnContainer}`}>
          {exportBtnClicked && (
              <Typography className={classes.warning}>
                {t('growerReports.delayWarning')}
              </Typography>
          )}
          {renderDownloadReports()}
          {loading && (
              <div className={classes.loaderWrapperOnDownload}>
                {renderLoaderOnDownload()}
              </div>
          )}
        </div>
      </div>
  );
}

export default GrowersReportsManagement;