import React, {useMemo} from 'react';
import {
    convertAndFormatDepth,
} from "../../../../../shared/measurements-converter/converter";
import {
    Measurement,
    MeasurementSys
} from "../../../../../shared/measurements-converter/measurement-enums/measurement-enums";
import {useAppDispatch, useAppSelector} from "../../../../../reduxToolkit/store/hooks";
import {openModal} from "../../../../../reduxToolkit/slices/GrowerFarmsSlice/GrowerFarms.slice";
import {GrowerModal} from "../../Grower";
import DesignedCard from './designed_card';
import {useTranslation} from "react-i18next";
import {formatNumberWithLabel} from "../modals/utils";

interface IrrigationRequirementDataProps {
    measurement_system: MeasurementSys;
    measurement: Measurement;
    irrigation_requirement: number;
    block_area: number;
    system_flow_rate?: number;
    userCountry: string;
}

const IrrigationRequirementDataCard: React.FC<IrrigationRequirementDataProps> = ({
     measurement_system,
     measurement,
     irrigation_requirement,
     system_flow_rate,
     block_area,
     userCountry
    }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const currentBlock = useAppSelector((state) => state.rootReducer.blockState.currentBlock);

    const value: string = formatNumberWithLabel(
        irrigation_requirement,
        userCountry,
        measurement_system,
        measurement,
        2,
        currentBlock?.area
    );
    const hourly_value = useMemo(() => {
        return system_flow_rate ? convertAndFormatDepth(irrigation_requirement, block_area, system_flow_rate, t) : '';
    }, [irrigation_requirement, block_area, system_flow_rate]);

return (
    <div onClick={() => dispatch(openModal(GrowerModal.irrigationRequirement))}>
        <DesignedCard
            value={value}
            time={system_flow_rate ?hourly_value : null}
            title={t('blockDataWidget.irrigation_requirement')}
            span={t('blockDataWidget.today')}
            leftIconName={"water_full"}
        />
    </div>
);
};

export default IrrigationRequirementDataCard;
