import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { updateBlockField } from 'src/reduxToolkit/slices/AddOrEditBlockSlice/addOrEditBlock.slice';
import { useAppDispatch, useAppSelector } from 'src/reduxToolkit/store/hooks';
import {
  convertAndRound,
  convertLabel,
} from 'src/shared/measurements-converter/converter';
import { MeasurementSystem } from 'src/shared/data/types/UserTypes';
import CropType from './CropType';
import InputFormField from './InputFormField';
import SelectFormField from './SelectFormField';
import LabelFormField from './InputFormField/LabelFormField';
import SelectCropVariety from './SelectCropVariety';
import useStyles from './useStyles';
import {
  Measurement,
  MeasurementSys,
} from '../../../../shared/measurements-converter/measurement-enums/measurement-enums';
import {
  MiniBlock,
  MiniFarm,
} from '../../../../shared/data/types/growerFarmsTypes';
import { BlockModal } from '../../../Growers/Grower/Grower';
import {
  cropTypeName,
  irrigationSystemName,
  soilTextureName,
  varietyName,
} from '../../../../shared/data/BlockUtils';
import { Checkbox } from 'antd'; // Assuming you're using Ant Design

export interface BlockInfoFormProps {
  isUniqueTitle: boolean;
  setIsUniqueTitle: (value: any) => void;
  block?: MiniBlock;
  farm: MiniFarm;
  blockModal: BlockModal;
  onCheckboxChange: (checked: boolean) => void;
  checkboxState: boolean;// Handler to pass state to parent

}

function BlockInfoForm(props: BlockInfoFormProps): JSX.Element {
  const { blockModal, isUniqueTitle, setIsUniqueTitle, farm, onCheckboxChange, checkboxState } = props;
  const { block, formOptions, installers, newPolygonAreaAfterEdit } =
      useAppSelector((state) => state.rootReducer.addOrEditBlockState);
  const selectedMeasurementSystem = useAppSelector(
      (state) => state.rootReducer.userState.measurementSystem,
  );

  const hasFlowSensor = useAppSelector((state) => state.rootReducer.blockState.currentBlock?.hasFlowSensor);

    const userCountry = useAppSelector(
      (state) => state.rootReducer.userState.country,
  );

  const [convertedArea, setConvertedArea] = useState<number | null | undefined>(
      convertAndRound(
          userCountry,
          selectedMeasurementSystem === MeasurementSystem.Metric
              ? MeasurementSys.METRIC
              : MeasurementSys.IMPERIAL,
          Measurement.AREA,
          block?.area ?? newPolygonAreaAfterEdit,
          2,
      ),
  );
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const [varietyDefaultValue, setVarietyDefaultValue] = useState(
      `${block?.cropVarietyId ?? ''}`,
  );
  const [isInBlur, setIsInBlur] = useState<boolean>(false);
  const { t } = useTranslation();

  const measureSys =
      selectedMeasurementSystem === MeasurementSystem.Metric
          ? MeasurementSys.METRIC
          : MeasurementSys.IMPERIAL;

  useEffect(() => {
    const areaValue =
        blockModal === BlockModal.edit ? block?.area : newPolygonAreaAfterEdit;
    setConvertedArea(
        convertAndRound(userCountry, measureSys, Measurement.AREA, areaValue, 2),
    );
  }, [
    block,
    newPolygonAreaAfterEdit,
    blockModal,
    userCountry,
    selectedMeasurementSystem,
    measureSys,
  ]);

  const isFormOptionsExist = useMemo(
      () => formOptions !== undefined && Object.keys(formOptions).length !== 0,
      [formOptions],
  );

  const handleChangeCrop = useCallback(
      (value) => {
        if (isFormOptionsExist) {
          dispatch(updateBlockField({ field: 'cropTypeId', value: value }));
          setVarietyDefaultValue('');
          dispatch(updateBlockField({ field: 'cropVarietyId', value: null }));
        }
      },
      [dispatch, isFormOptionsExist],
  );

  const handleChangeVariety = useCallback(
      (varietyType: string) => {
        setVarietyDefaultValue(varietyType);
        dispatch(
            updateBlockField({ field: 'cropVarietyId', value: varietyType }),
        );
      },
      [dispatch],
  );

  const handleBlur = useCallback(() => {
    setIsInBlur(true);
  }, []);

  const handleCheckboxChangeLocal = useCallback(
      (e: any) => {
        const checked = e.target.checked;
        onCheckboxChange(checked); // Notify parent
        if (!checked) {
          // If the checkbox is unchecked, clear the values of the three fields
          dispatch(updateBlockField({ field: 'numberOfIrrigationPipes', value: undefined }));
          dispatch(updateBlockField({ field: 'distanceBetween', value: undefined }));
          dispatch(updateBlockField({ field: 'flowRatio', value: undefined }));
        }
      },
      [dispatch, onCheckboxChange]
  );

  const renderCropVariety = (): any => {
    const varietyArr: any =
        formOptions?.varietyOptions[`${block?.cropTypeId}`]?.map((opt: any) => ({
          value: `${opt.id}`,
          content: opt.name,
        })) ?? [];

    return (
        <div className={classes.twoInputsInARow}>
          {blockModal === BlockModal.edit ? (
              <LabelFormField
                  farm={farm}
                  title={`${t('BlockInfoForm.crop')}*`}
                  fieldName="cropTypeId"
                  value={cropTypeName(formOptions, block?.cropTypeId)}
                  placeholder={t('BlockInfoForm.selectCropPlaceholder')}
              />
          ) : (
              <SelectCropVariety
                  title={`${t('BlockInfoForm.crop')}*`}
                  fieldName="cropTypeId"
                  onChange={handleChangeCrop}
                  options={formOptions.cropOptions?.map((key) => ({
                    value: `${key.id}`,
                    content: <CropType key={key.id} crop={key} />,
                  }))}
                  placeholder={t('BlockInfoForm.selectCropPlaceholder')}
                  errorMessage={`${t('BlockInfoForm.selectCropErrorMessage')}`}
                  value={`${block?.cropTypeId ?? ''}`}
                  handleBlur={handleBlur}
                  isInBlur={isInBlur}
                  filterOption={(input: string, option: any) =>
                      option.props?.children?.props?.crop?.name
                          ?.toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                  }
              />
          )}
          {blockModal === BlockModal.edit ? (
              <LabelFormField
                  farm={farm}
                  title={t('BlockInfoForm.variety')}
                  fieldName="cropTypeId"
                  value={varietyName(
                      formOptions,
                      block?.cropTypeId,
                      block?.cropVarietyId,
                  )}
                  placeholder={t('BlockInfoForm.selectVarietyPlaceholder')}
              />
          ) : (
              <SelectCropVariety
                  title={t('BlockInfoForm.variety')}
                  fieldName="cropVarietyId"
                  onChange={handleChangeVariety}
                  options={[...varietyArr]}
                  placeholder={t('BlockInfoForm.selectVarietyPlaceholder')}
                  handleBlur={handleBlur}
                  isInBlur={isInBlur}
                  value={varietyDefaultValue}
                  filterOption={(input: string, option: any) =>
                      option.props?.children
                          ?.toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                  }
              />
          )}
        </div>
    );
  };

  return (
      <div className={classes.container}>
        {isFormOptionsExist ? (
            <form className={classes.root}>
              <div className={classes.twoInputsInARow}>
                <InputFormField
                    farm={farm}
                    title={t('BlockInfoForm.blockName')}
                    fieldName="title"
                    placeholder={t('BlockInfoForm.blockNamePlaceholder')}
                    errorMessage={`${t('BlockInfoForm.blockNameErrorMessage')}`}
                    maxLength={20}
                    isUniqueTitle={isUniqueTitle}
                    setIsUniqueTitle={setIsUniqueTitle}
                />
                <LabelFormField
                    farm={farm}
                    title={`${t('BlockInfoForm.blockArea')} (${convertLabel(
                        userCountry,
                        measureSys,
                        Measurement.AREA,
                    )})`}
                    fieldName="area"
                    value={`${convertedArea ?? 0}`}
                    placeholder={t('BlockInfoForm.blockAreaPlaceholder')}
                />
              </div>
              <div className={classes.twoInputsInARow}>{renderCropVariety()}</div>
              <div className={classes.twoInputsInARow}>
                {blockModal === BlockModal.edit ? (
                    <LabelFormField
                        farm={farm}
                        title={`${t('BlockInfoForm.treeSpacing')} (${convertLabel(
                            userCountry,
                            measureSys,
                            Measurement.LENGTH,
                        )})*`}
                        fieldName="treeSpacing"
                        value={`${convertAndRound(
                            userCountry,
                            measureSys,
                            Measurement.LENGTH,
                            block?.treeSpacing,
                            2,
                        )}`}
                        placeholder={t('BlockInfoForm.treeSpacingPlaceholder')}
                    />
                ) : (
                    <InputFormField
                        farm={farm}
                        title={`${t('BlockInfoForm.treeSpacing')} (${convertLabel(
                            userCountry,
                            measureSys,
                            Measurement.LENGTH,
                        )})*`}
                        fieldName="treeSpacing"
                        placeholder={t('BlockInfoForm.treeSpacingPlaceholder')}
                        value=""
                        isNumber
                        minNumber={1}
                        errorMessage={`${t('BlockInfoForm.treeSpacingErrorMessage')}`}
                        errorValidNumMessage={`${t(
                            'BlockInfoForm.treeSpacingErrorValidNumMessage',
                        )}`}
                    />
                )}
                {blockModal === BlockModal.edit ? (
                    <LabelFormField
                        farm={farm}
                        title={`${t('BlockInfoForm.rowSpacing')} (${convertLabel(
                            userCountry,
                            measureSys,
                            Measurement.LENGTH,
                        )})*`}
                        fieldName="rowSpacing"
                        value={`${
                            convertAndRound(
                                userCountry,
                                measureSys,
                                Measurement.LENGTH,
                                block?.rowSpacing,
                                2,
                            ) ?? ''
                        }`}
                        placeholder={t('BlockInfoForm.rowSpacingPlaceholder')}
                    />
                ) : (
                    <InputFormField
                        farm={farm}
                        title={`${t('BlockInfoForm.rowSpacing')} (${convertLabel(
                            userCountry,
                            measureSys,
                            Measurement.LENGTH,
                        )})*`}
                        fieldName="rowSpacing"
                        placeholder={t('BlockInfoForm.rowSpacingPlaceholder')}
                        isNumber
                        minNumber={1}
                        errorMessage={`${t('BlockInfoForm.rowSpacingErrorMessage')}`}
                        errorValidNumMessage={`${t(
                            'BlockInfoForm.rowSpacingErrorValidNumMessage',
                        )}`}
                    />
                )}
              </div>
              <div className={classes.twoInputsInARow}>
                {blockModal === BlockModal.edit ? (
                    <LabelFormField
                        farm={farm}
                        title={t('BlockInfoForm.soilTexture')}
                        fieldName="soilTextureId"
                        value={soilTextureName(formOptions, block?.soilTextureId)}
                        placeholder={t('BlockInfoForm.soilTexturePlaceholder')}
                    />
                ) : (
                    <SelectFormField
                        title={t('BlockInfoForm.soilTexture')}
                        fieldName="soilTextureId"
                        options={formOptions.soilTextureOptions?.map((opt: any) => ({
                          value: `${opt.id}`,
                          content: `${t(`${opt.name}`)}`,
                        }))}
                        placeholder={t('BlockInfoForm.soilTexturePlaceholder')}
                        handleBlur={handleBlur}
                        isInBlur={isInBlur}
                        filterOption={(input: string, option: any) =>
                            option.props?.children
                                ?.toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }
                    />
                )}
                {blockModal === BlockModal.edit ? (
                    <LabelFormField
                        farm={farm}
                        title={`${t('BlockInfoForm.plantingYear')}*`}
                        fieldName="plantingYear"
                        value={`${block?.plantingYear ?? ''}`}
                        placeholder={t('BlockInfoForm.plantingYearPlaceholder')}
                    />
                ) : (
                    <InputFormField
                        farm={farm}
                        title={`${t('BlockInfoForm.plantingYear')}*`}
                        fieldName="plantingYear"
                        placeholder={t('BlockInfoForm.plantingYearPlaceholder')}
                        isNumber
                        minNumber={1900}
                        errorMessage={`${t('BlockInfoForm.plantingYearErrorMessage')}`}
                        errorValidNumMessage={`${t(
                            'BlockInfoForm.plantingYearErrorValidNumMessage',
                        )}`}
                    />
                )}
              </div>
              <div className={classes.twoInputsInARow}>
                {blockModal === BlockModal.edit ? (
                    <LabelFormField
                        farm={farm}
                        title={t('BlockInfoForm.irrigationSystem')}
                        fieldName="irrigationSystemId"
                        value={irrigationSystemName(
                            formOptions,
                            block?.irrigationSystemId,
                        )}
                        placeholder={t('BlockInfoForm.irrigationSystemPlaceholder')}
                    />
                ) : (
                    <SelectFormField
                        title={t('BlockInfoForm.irrigationSystem')}
                        fieldName="irrigationSystemId"
                        options={formOptions.irrigationSystemOptions?.map((opt) => ({
                          value: `${opt.id}`,
                          content: `${t(`${opt.name}`)}`,
                        }))}
                        placeholder={t('BlockInfoForm.irrigationSystemPlaceholder')}
                        handleBlur={handleBlur}
                        isInBlur={isInBlur}
                        filterOption={(input: string, option: any) =>
                            option.props?.children
                                ?.toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }
                    />
                )}
                {blockModal === BlockModal.edit ? (
                    <LabelFormField
                        farm={farm}
                        title={`${t(
                            'BlockInfoForm.systemApplicationRate',
                        )} (${convertLabel(
                            userCountry,
                            measureSys,
                            Measurement.FLOW_RATE,
                        )})`}
                        fieldName="systemApplicationRate"
                        value={
                          block?.systemApplicationRate
                              ? `${convertAndRound(
                                  userCountry,
                                  measureSys,
                                  Measurement.FLOW_RATE,
                                  block.systemApplicationRate,
                                  3,
                              )}`
                              : ''
                        }
                        placeholder=""
                    />
                ) : (
                    <InputFormField
                        farm={farm}
                        title={`${t(
                            'BlockInfoForm.systemApplicationRate',
                        )} (${convertLabel(
                            userCountry,
                            measureSys,
                            Measurement.FLOW_RATE,
                        )})`}
                        fieldName="systemApplicationRate"
                        placeholder={t(
                            'BlockInfoForm.systemApplicationRatePlaceholder',
                        )}
                        isNumber
                        minNumber={0}
                        errorValidNumMessage={`${t(
                            'BlockInfoForm.systemApplicationRateErrorValidNumMessage',
                        )}`}
                    />
                )}
              </div>
              <div className={classes.singleInputRow}>
                {installers?.length > 0 && (
                    <SelectFormField
                        title={t('BlockInfoForm.installers')}
                        fieldName="installers"
                        options={installers.map((opt) => ({
                          value: opt.email,
                          content: opt.name,
                        }))}
                        placeholder={t('BlockInfoForm.installersPlaceholder')}
                        handleBlur={handleBlur}
                        isInBlur={isInBlur}
                        filterOption={(input: string, option: any) =>
                            option.props?.children
                                ?.toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }
                    />
                )}
              </div>
              <div className={classes.checkboxContainer}>
                <Checkbox
                    checked={checkboxState}
                    onChange={handleCheckboxChangeLocal}
                    disabled={blockModal === BlockModal.edit ? hasFlowSensor : false}
                >
                  {t('BlockInfoForm.hasFlowSensor')}
                </Checkbox>
              </div>
              {checkboxState && (
                  <>
                    <div className={classes.twoInputsInARow}>
                      {hasFlowSensor && blockModal === BlockModal.edit ? (
                          <LabelFormField
                              farm={farm}
                              title={`${t('BlockInfoForm.numberOfIrrigationPipes')}*`}
                              fieldName="numberOfIrrigationPipes"
                              value={`${block?.numberOfIrrigationPipes ?? ''}`}
                              placeholder={t('BlockInfoForm.numberOfIrrigationPipesPlaceholder')}
                          />
                      ) : (
                          <InputFormField
                              farm={farm}
                              title={`${t('BlockInfoForm.numberOfIrrigationPipes')}*`}
                              fieldName="numberOfIrrigationPipes"
                              placeholder={t('BlockInfoForm.numberOfIrrigationPipesPlaceholder')}
                              isNumber
                              minNumber={1}
                              errorMessage={t('BlockInfoForm.numberOfIrrigationPipesErrorMessage') as string}
                              errorValidNumMessage={t('BlockInfoForm.numberOfIrrigationPipesErrorValidNumMessage') as string}
                          />
                      )}

                      {hasFlowSensor && blockModal === BlockModal.edit ? (
                          <LabelFormField
                              farm={farm}
                              title={t('BlockInfoForm.distanceBetween')}
                              fieldName="distanceBetween"
                              value={`${block?.distanceBetween ?? ''}`}
                              placeholder={t('BlockInfoForm.distanceBetweenPlaceholder')}
                          />
                      ) : (
                          <InputFormField
                              farm={farm}
                              title={t('BlockInfoForm.distanceBetween')}
                              fieldName="distanceBetween"
                              placeholder={t('BlockInfoForm.distanceBetweenPlaceholder')}
                              isNumber
                              minNumber={0}
                              errorValidNumMessage={t('BlockInfoForm.distanceBetweenErrorValidNumMessage') as string}
                          />
                      )}
                    </div>

                    <div className={classes.singleInputRow}>
                      {hasFlowSensor && blockModal === BlockModal.edit ? (
                          <LabelFormField
                              farm={farm}
                              title={t('BlockInfoForm.flowRatio')}
                              fieldName="flowRatio"
                              value={`${block?.flowRatio ?? ''}`}
                              placeholder={t('BlockInfoForm.flowRatioPlaceholder')}
                          />
                      ) : (
                          <InputFormField
                              farm={farm}
                              title={t('BlockInfoForm.flowRatio')}
                              fieldName="flowRatio"
                              placeholder={t('BlockInfoForm.flowRatioPlaceholder')}
                              isNumber
                              minNumber={0}
                              errorValidNumMessage={t('BlockInfoForm.flowRatioErrorValidNumMessage') as string}
                          />

                      )}
                    </div>
                  </>
              )}
            </form>
        ) : (
            <> </>
        )}
      </div>
  );
}

export default BlockInfoForm;

