import React from 'react';
import useDesignedCard from './useDesignedCard';
import GenericIcon from "../generic_icon";
import { IconFile } from "../../../../../assets/Icons/blockData";
import { useAppSelector } from "../../../../../reduxToolkit/store/hooks";
import { ValueTime } from "../constants";
import { useWindowDimensions } from "../../../../../shared/hooks/useWindowDimensions";
import {largeMaxWidth, smallMaxWidth, xSmallMaxHeight} from "../../../../../shared/constants/consts";
import {useTranslation} from "react-i18next";

interface DesignedCardProps {
    value?: string;
    time?: string | number | null;
    title: string;
    span: string;
    leftIconName: keyof typeof IconFile; // Prop to pass the left icon name
}

const DesignedCard: React.FC<DesignedCardProps> = ({
       value,
       time,
       title,
       leftIconName,
       span,
   }) => {
    const { t } = useTranslation();
    const classes = useDesignedCard();

    const value_time = useAppSelector(
        (state) => state.rootReducer.userState.value_time,
    );

    const display = value_time === ValueTime.time && time;
    const { width, height } = useWindowDimensions();

    let iconSize: number;
    if (width > smallMaxWidth && height <= xSmallMaxHeight) {
        iconSize = 16;
    } else if (width <= smallMaxWidth) {
        iconSize = 16;
    } else if (width <= largeMaxWidth) {
        iconSize = 20;
    } else {
        iconSize = 30;
    }

    // Compute subtitle with 'or' if both time and value are present
    let subtitleContent: string | number | null | undefined;
    if (value && time) {
        subtitleContent = `${t('blockDataWidget.or')} ${display ? value : time}`;
    } else {
        subtitleContent = display ? value : time;
    }

    return (
        <div className={classes.allContainer}>
            <div className={classes.titleContainer}>
                <div className={classes.title}>
                    {title}
                </div>
                <div className={classes.span}>
                    {`(${span})`}
                </div>
            </div>
            <div className={classes.cardContainer}>
                <div className={classes.iconContainer}>
                    <div className={classes.leftIconCircle}>
                        <GenericIcon name={leftIconName} size={iconSize}>
                            {leftIconName}
                        </GenericIcon>
                    </div>
                </div>
                <div className={classes.contentContainer}>
                    <div className={classes.mainTitle}>
                        {display ? time : value}
                    </div>
                    <div className={classes.subTitle}>
                        {subtitleContent}
                    </div>
                </div>
                <div className={classes.iconContainer}>
                    <GenericIcon name={'chevron_right'} size={iconSize}/>
                </div>
            </div>
        </div>
    );
};

export default DesignedCard;