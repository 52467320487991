// Grower.tsx
import React, { useCallback, useEffect, useState } from 'react';
import useStyles from './useStyles';

// Redux Imports
import getBlockData from '../../../reduxToolkit/thunks/block_data_thunks';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../reduxToolkit/store/hooks';
import { setBlockData as setReduxBlockData } from '../../../reduxToolkit/slices/BlockDataSlice/blockData.slice';
import { setFlowData as setReduxFlowData } from "../../../reduxToolkit/slices/FlowData/flowData.slice";
import {
  setCreationInProgress,
  setIsInEditMode,
  setNewPolygonAreaAfterEdit,
  setNumOfClicksOnMapDuringCreation,
} from '../../../reduxToolkit/slices/AddOrEditBlockSlice/addOrEditBlock.slice';
import { closeModal } from '../../../reduxToolkit/slices/GrowerFarmsSlice/GrowerFarms.slice';

// Types
import {
  MiniBlock,
  MiniFarm,
  MiniGrower,
} from '../../../shared/data/types/growerFarmsTypes';
import { BlockData } from '../../../shared/data/types/blockDataTypes';

// Components
import FarmMap from './FarmMap';
import ProgressBar from '../../../shared/components/ProgressBar';
import AddFarmModal from '../../FieldsPage/FarmManagement/FarmModal/AddFarmModal';
import Modal from '../../../shared/components/Modal/Modal';
import AddOrEditBlockForm from '../../FieldsPage/AddOrEditBlockForm';
import DeleteBlockForm from '../../FieldsPage/DeleteBlockForm';
import NewBlockAddPolygonBackground from '../../FieldsPage/NewBlockAddPolygonBackground';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import GrowersReportsManagement from '../../FieldsPage/Report/GrowersReportsManagement/GrowersReportsManagement';
import ResearcherReportsManagement from '../../FieldsPage/Report/ResearcherReportsManagement';
import { useSearchParams } from 'react-router-dom';
import SensorWidget from '../../SensorsPage/SensorWidget';
import MiniBlockDataWidget from "./block_data_widget/mini_block_data_widget";
import ActualConsumptionModal from "./block_data_widget/modals/actual_consumption_modal";
import IrrigationRequirementModal from "./block_data_widget/modals/irrigation_requirement_modal";
import ActualIrrigationModal from "./block_data_widget/modals/actual_irrigation_modal";
import { getMeasurementSys } from "./block_data_widget/modals/utils";
import { DepthVolume } from "./block_data_widget/constants";
import { Measurement } from "../../../shared/measurements-converter/measurement-enums/measurement-enums";
import {getFlowData} from "../../../reduxToolkit/thunks/flow_thunks";
import MiniMenuDataWidget from "./menu_data_widget/mini_menu_data_widget";
import { setCurrentBlock } from "../../../reduxToolkit/slices/BlockSlice/block.slice";

function Grower(props: GrowerWidgetProps): JSX.Element {
  // Hooks
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  // Props
  const { grower, farmId, blockId } = props;
  const showSensors: boolean = !!searchParams.get('sensors');

  // State
  const [currentFarm, setCurrentFarm] = useState<MiniFarm | undefined>();
  const [blockData, setBlockData] = useState<BlockData>();
  const [shouldResetHighlight, setShouldResetHighlight] = useState<boolean>(false);
  const [firstTimeFlag, setFirstTimeFlag] = useState(() => true);
  const filteredArray = currentFarm?.blocks.filter((item) => item !== null);
  const noBlocks = !filteredArray || filteredArray.length === 0;
  // Redux State Selectors
  const currentBlock = useAppSelector((state) => state.rootReducer.blockState.currentBlock);
  const { flow_data } = useAppSelector((state) => state.rootReducer.flowDataState);
  const { data } = useAppSelector((state) => state.rootReducer.blockDataState);
  const { modal } = useAppSelector((state) => state.rootReducer.GrowerFarmsState);
  const isToroAccount = useAppSelector(
      (state) => state.rootReducer.accountState.isToroAccount,
  );
  const { isNewBlockCreationInProgress, numOfClicksOnMapDuringBlockCreation } =
      useAppSelector((state) => state.rootReducer.addOrEditBlockState);

  const measurement_system = useAppSelector(
      (state) => state.rootReducer.userState.measurementSystem,
  );
  const value_time = useAppSelector(
      (state) => state.rootReducer.userState.value_time,
  );

  const depth_volume = useAppSelector(
      (state) => state.rootReducer.userState.depth_volume,
  );

  const userCountry = useAppSelector((state) => state.rootReducer.userState.country);
  const measurementSys = getMeasurementSys(measurement_system);

  const measurement = depth_volume === DepthVolume.volume ?
      Measurement.VOLUME :
      Measurement.PRECIPITATION;

  // Helper Functions
  function shouldSetFarm(
      farm: MiniFarm | undefined,
      farmId: number | undefined,
  ) {
    return !farm || (farmId && farm.id !== farmId);
  }

  function shouldSetBlock(
      block: MiniBlock | undefined,
      blockId: number | undefined,
  ) {
    return !block || (blockId && block.id !== blockId);
  }

  useEffect(() => {
    setCurrents(blockId, farmId);
  }, [blockId, farmId]);

  function setCurrents(blockId?: number, farmId?: number) {
    let farm;
    let block;
    const willSetFarm = shouldSetFarm(currentFarm, farmId);
    if (willSetFarm) {
      farm = blockId ? findFarmByBlockId(blockId) : findFarm(farmId);
      setCurrentFarm(farm);
    }

    if (willSetFarm || shouldSetBlock(currentBlock, blockId)) {
      block = findBlock(farm ?? currentFarm, blockId);
      dispatch(setCurrentBlock(block)); // Now using Redux to set current block
    }
  }

  useEffect(() => {
    if (currentBlock?.id) {
      fetchBlockData(currentBlock.id).then((r) => r);
      fetchFlowData(currentBlock.id).then((r) => r);
    }
  }, [currentBlock]);

  useEffect(() => {
    if (!firstTimeFlag) {
      const farm = findFarm(farmId);
      setCurrentFarm(farm);
      const block = findBlock(farm, blockId);
      setCurrentBlock(block);
    }
    setFirstTimeFlag(false);
  }, [grower]);

  const toggleBlockCreationInProgress = useCallback(() => {
    dispatch(setNewPolygonAreaAfterEdit(0));
    dispatch(setCreationInProgress(!isNewBlockCreationInProgress));
    dispatch(setNumOfClicksOnMapDuringCreation(0));
    dispatch(setIsInEditMode(false));
  }, [dispatch, isNewBlockCreationInProgress]);

  // UI Actions

  // Helpers
  function findFarm(farmId?: number): MiniFarm {
    return grower.farms.find((farm) => farm.id === farmId) || grower.farms[0];
  }

  function findFarmByBlockId(blockId?: number): MiniFarm | undefined {
    if (!blockId) return grower.farms[0];

    return grower.farms.find((farm) => {
      return farm.blocks.find((block) => block.id === blockId);
    });
  }

  function findBlock(farm?: MiniFarm, bid?: number): MiniBlock | undefined {
    if (!farm) return;

    return (
        farm?.blocks?.find((block) => block?.id === bid) ?? farm?.blocks?.[0]
    );
  }

  async function fetchBlockData(blockId?: number) {
    if (typeof blockId !== 'number' || blockId <= 0) {
      console.error('Invalid block ID');
      return;
    }

    // Clean block data
    setBlockData(undefined);

    // Check if exists in Redux
    const cachedBlockData = data[blockId];
    if (cachedBlockData) {
      setBlockData(cachedBlockData);
      return;
    }

    // Or fetch from server
    const bData = await fetchBlockDataFromServer(blockId);
    if (bData) {
      // Set in Redux
      dispatch(setReduxBlockData(bData));
      setBlockData(bData);
    }
  }

  async function fetchFlowData(blockId?: number) {
    if (typeof blockId !== 'number' || blockId <= 0) {
      console.error('Invalid block ID');
      return;
    }

    const cachedFlowData = flow_data[blockId];
    if (cachedFlowData) {
      return;
    }

    // Fetch from server
    const fData = await fetchFlowDataFromServer(blockId);
    if (fData && currentBlock?.area) {
      // Convert flow data using block area
      const convertedFlowData = fData.map(flow => ({
        ...flow,
        flow: convertFlowToMillimeters(flow.flow, currentBlock.area),
      }));

      // Set converted flow data in Redux
      dispatch(setReduxFlowData(convertedFlowData));
    }
  }

  function convertFlowToMillimeters(flow: number, area: number): number  {
    if (area) {
        return (flow / 1000) / (area / 1000);
      }
    return 0;
  }



  async function fetchBlockDataFromServer(blockId: number) {
    try {
      return await dispatch(getBlockData({ id: blockId })).unwrap();
    } catch (error) {
      console.error('Failed to fetch block data:', error);
    }
  }

  async function fetchFlowDataFromServer(blockId: number) {
    try {
      return await dispatch(getFlowData({ id: blockId })).unwrap();
    } catch (error) {
      console.error('Failed to fetch flow data:', error);
    }
  }

  function renderModal(): JSX.Element {
    switch (modal) {
      case GrowerModal.createFarm:
        return (
            <Modal isOpen={!!modal} onCancel={() => dispatch(closeModal())}>
              <AddFarmModal grower={grower} isToroAccount={isToroAccount} />
            </Modal>
        );
      case GrowerModal.createBlock:
        return (
            <Modal isOpen={!!modal} onCancel={() => dispatch(closeModal())}>
              <AddOrEditBlockForm
                  blockModal={BlockModal.create}
                  farm={currentFarm!}
              />
            </Modal>
        );
      case GrowerModal.editBlock:
        return (
            <Modal isOpen={!!modal} onCancel={() => dispatch(closeModal())}>
              <AddOrEditBlockForm
                  blockModal={BlockModal.edit}
                  block={currentBlock}
                  farm={currentFarm!}
              />
            </Modal>
        );
      case GrowerModal.deleteBlock:
        return (
            <Modal isOpen={!!modal} onCancel={() => dispatch(closeModal())}>
              <DeleteBlockForm block={currentBlock} />
            </Modal>
        );
      case GrowerModal.growerFarmsReport:
        return (
            <Modal isOpen={!!modal} onCancel={() => dispatch(closeModal())}>
              <GrowersReportsManagement />
            </Modal>
        );
      case GrowerModal.researcherFarmsReport:
        return (
            <Modal isOpen={!!modal} onCancel={() => dispatch(closeModal())}>
              <ResearcherReportsManagement />
            </Modal>
        );
      case GrowerModal.actualConsumption:
        return <ActualConsumptionModal data={blockData?.actualConsumption} userCountry={userCountry} measurementSystem={measurementSys} measurement={measurement} />;
      case GrowerModal.irrigationRequirement:
        return <IrrigationRequirementModal data={blockData?.irrigationRequirement} userCountry={userCountry} measurementSystem={measurementSys} measurement={measurement} flowRate={currentBlock?.flowRate}/>;
      case GrowerModal.actualIrrigation:
        // Safely access flow_data only if currentBlock and its id exist
        return currentBlock?.id !== undefined ? (
            <ActualIrrigationModal
                flowData={flow_data[currentBlock.id]}
                userCountry={userCountry}
                measurementSystem={measurementSys}
                measurement={measurement}
            />
        ) : (
            <></>  // Return an empty fragment or handle the undefined case differently if needed
        );
      default:
        return <></>;
    }
  }

  function renderSensors(): JSX.Element {
    return currentBlock && currentFarm ? (
        <SensorWidget block={currentBlock} farm={currentFarm} />
    ) : (
        <></>
    );
  }

  return (
      <div>
        {isNewBlockCreationInProgress && (
            <NewBlockAddPolygonBackground
                mapClickAddNewBlock={numOfClicksOnMapDuringBlockCreation}
                onButtonClicked={toggleBlockCreationInProgress}
                setShouldResetHighlight={setShouldResetHighlight}
            />
        )}
        <div className={classes.root}>
          <div className={classes.menu}>
            {currentFarm && (
                <MiniMenuDataWidget
                    grower={grower}
                    currentFarm={currentFarm}
                    currentBlock={currentBlock}
                />
            )}
          </div>
          <div className={classes.content}>
            <div className={classes.mapContainer}>
              {currentFarm && (
                  <FarmMap
                      farm={currentFarm}
                      currentBlock={currentBlock}
                      shouldResetHighlight={shouldResetHighlight}
                  />
              )}
            </div>
            <div className={classes.miniBlockData}>
              {showSensors ? (
                  renderSensors() // Renders sensors if applicable
              ) : (
                  <>
                    {noBlocks && (
                        <div className={`flex align-center justify-between ${classes.noBlockFarmInfo}`}>
                          <Button
                              className={
                                !isNewBlockCreationInProgress
                                    ? classes.addBlockBtn
                                    : classes.btnHidden
                              }
                              onClick={toggleBlockCreationInProgress}
                          >
                            {t('fields.noBlocksMsg')}
                          </Button>
                        </div>
                    )}
                    {currentBlock &&
                        (blockData ? (
                            <MiniBlockDataWidget
                                block_data={blockData}
                                block={currentBlock}
                                farm={currentFarm}
                                userCountry={userCountry}
                                measurementSystem={measurementSys}
                                measurement={measurement}
                                depth_volume={depth_volume}
                                value_time={value_time}
                                flowData={flow_data[currentBlock.id]}
                            />
                        ) : (
                            <div className={classes.progressBarContainer}>
                              <ProgressBar/>
                            </div>
                        ))}
                  </>
              )}
            </div>
          </div>
        </div>
        {renderModal()}
      </div>
  );
}

export default Grower;

interface GrowerWidgetProps {
  grower: MiniGrower;
  farmId?: number;
  blockId?: number;
}

export enum GrowerModal {
  createFarm = 1,
  createBlock,
  editBlock,
  deleteBlock,
  growerFarmsReport,
  researcherFarmsReport,
  actualConsumption,
  irrigationRequirement,
  actualIrrigation,
}

export enum BlockModal {
  create = 1,
  edit,
}
