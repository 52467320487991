import React, {useMemo, useState} from 'react';
import {DepthVolume, ValueTime} from './constants';
import DepthVolumeToggle from "./toggles/depth_volume_toggle";
import MeasurementSystemToggle from "./toggles/measurement_system_toggle";
import ValueTimeToggle from "./toggles/value_time_toggle";
import IrrigationRequirementDataCard from "./card/irrigation_requirement_data_card";
import ActualConsumptionDataCard from "./card/actual_consumption_data_card";
import ActualIrrigationDataCard from "./card/actual_irrigation_data_card";
import {MiniBlock, MiniFarm} from "../../../../shared/data/types/growerFarmsTypes";
import { useAppSelector} from "../../../../reduxToolkit/store/hooks";
import {
    Measurement, MeasurementSys,
} from "../../../../shared/measurements-converter/measurement-enums/measurement-enums";
import {ActualIrrigation, BlockData} from "../../../../shared/data/types/blockDataTypes";
import BlockDataGraph from "../BlockData/BlockDataGraph";
import useMiniBlockDataWidgetStyles from "./ useMiniBlockDataWidgetStyles";
import { useTranslation } from 'react-i18next';

interface BlockDataWidgetProps {
    block: MiniBlock;
    farm?: MiniFarm;
    block_data: BlockData;
    userCountry: string;
    measurementSystem: MeasurementSys;
    measurement: Measurement;
    depth_volume: DepthVolume
    value_time: ValueTime;
    flowData?: ActualIrrigation[];
}
const MiniBlockDataWidget: React.FC<BlockDataWidgetProps> = ({
         block,
         block_data,
         farm,
         userCountry,
         measurementSystem,
         measurement,
         depth_volume,
         value_time,
         flowData
     }) => {
    const classes = useMiniBlockDataWidgetStyles(); // Use the extracted styles
    const { t } = useTranslation();

    const actual_consumption = block_data.actualConsumption?.[0]?.ETa || 0;
    const irrigation_requirement = block_data.irrigationRequirement?.[0]?.irrigForecast || 0;
    const block_area = block.area
    const system_flow_rate = block.flowRate

    const currentGrowerId = useAppSelector(
        (state) => state.rootReducer.GrowerFarmsState.currentGrower?.id,
    );
    const demoUserActualConsumptionData = useAppSelector(
        (state) => state.rootReducer.demoUserState.demoUserActualConsumptionData,
    );
    const useSubId = useAppSelector((state) => state.rootReducer.userState.sub);
    const isToroAccount = useAppSelector(
        (state) => state.rootReducer.accountState.isToroAccount,
    );

    const [zoomStartValue, setZoomStartValue] = useState(14);
    const [zoomEndValue, setZoomEndValue] = useState(1);

    const handleZoomIn = (): void => {
        if (Math.abs(zoomStartValue - zoomEndValue) > 14) {
            const zoomEnVa = zoomEndValue + 5;
            setZoomEndValue(zoomEnVa);
            const zoomStVa = zoomStartValue - 5;
            setZoomStartValue(zoomStVa);
        }
    };

    const handleZoomOut = (): void => {
        const zoomEnVa = zoomEndValue - 5;
        setZoomEndValue(zoomEnVa);
        const zoomStVa = zoomStartValue + 5;
        setZoomStartValue(zoomStVa);
    };

    const isDemoUserAccount = useMemo(
        () =>
            currentGrowerId === process.env.REACT_APP_DEMO_USER_ID ||
            useSubId === process.env.REACT_APP_DEMO_USER_USER_SUB_ID,
        [currentGrowerId, useSubId],
    );

    const isNetafimAccount =
        currentGrowerId === process.env.REACT_APP_NETAFIM_ID ||
        useSubId === process.env.REACT_APP_NETAFIM_USER_SUB_ID;


    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <div className={classes.blockTitle}>
                    {block.title} {t('blockDataWidget.blocks_data')}
                </div>
                <div className={classes.toggleContainer}>
                    <MeasurementSystemToggle measurement_system={measurementSystem}  />
                    <ValueTimeToggle value_time={value_time} />
                    <DepthVolumeToggle depth_volume={depth_volume} />

                </div>
            </div>
            <div className={classes.dataCardContainer}>
                <ActualConsumptionDataCard
                    measurement_system={measurementSystem}
                    measurement={measurement}
                    userCountry={userCountry}
                    actual_consumption={actual_consumption}
                />
                <IrrigationRequirementDataCard
                    measurement_system={measurementSystem}
                    measurement={measurement}
                    userCountry={userCountry}
                    irrigation_requirement={irrigation_requirement}
                    system_flow_rate={system_flow_rate}
                    block_area={block_area}
                />
                {flowData && (
                    <ActualIrrigationDataCard
                        measurement_system={measurementSystem}
                        measurement={measurement}
                        userCountry={userCountry}
                        actual_irrigation={flowData[flowData.length - 1]}
                    />
                )}
            </div>
            <div className={classes.graphContainer}>
                <BlockDataGraph
                    flowData={flowData}
                    startValueProp={zoomStartValue}
                    endValueProp={zoomEndValue}
                    handleZoomIn={handleZoomIn}
                    handleZoomOut={handleZoomOut}
                    blockData={block_data}
                    block={block}
                    currentGrowerId={currentGrowerId}
                    farm={farm}
                    isToroAccount={isToroAccount}
                    demoUserActualConsumptionData={demoUserActualConsumptionData}
                    isDemoUserAccount={isDemoUserAccount}
                    isNetafimAccount={isNetafimAccount}
                />
            </div>
        </div>
    );
};

export default MiniBlockDataWidget;