// src/adapters/FlowAdapter.ts

import { AxiosResponse } from 'axios';
import { HttpClientService } from 'src/services/HttpClientService';
import { ActualIrrigation } from 'src/shared/data/types/blockDataTypes';

// Define an interface for the IMEI response
interface ImeiResponse {
    imei: string;
}

class FlowDataAdapter {
    static readonly endpoint = `flow-data`;

    async getFlowData(id: number): Promise<AxiosResponse<ActualIrrigation[]>> {
        return HttpClientService.get<ActualIrrigation[]>(`${FlowDataAdapter.endpoint}`, {
            params: { id },
        });
    }

    // Update the return type to match the actual API response
    async getUnitRealtimeIrrigation(imeis: string[]): Promise<AxiosResponse<ImeiResponse[]>> {
        // Wrap each IMEI in single quotes
        const quotedImeis = imeis.map(id => `'${id}'`).join(',');

        return HttpClientService.get<ImeiResponse[]>(`${FlowDataAdapter.endpoint}/realtime-irrigation`, {
            params: { ids: quotedImeis }, // Server expects 'ids' as comma-separated string with quotes
        });
    }
}

const flowDataAdapter = new FlowDataAdapter();
export default flowDataAdapter;