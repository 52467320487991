import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    alignItems: 'flex-start',
    width: '100%',
  },
  iconBtn: {
    cursor: 'pointer', // Pointer cursor for hover effect
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.paddings.s,
    '&:hover': {
      cursor: 'pointer', // Ensure pointer on hover
    },
  },
  actions: {
    marginLeft: 'auto',
    alignSelf: 'end',
    display: 'flex',
    gap: theme.paddings.s,
  },
  optionsContainer: {
    flex: 1,
    width: '100%',
    alignItems: 'baseline',
  },
  container: {
    backgroundColor: theme.palette.surfaceWhite.main,
    borderRadius: theme.shape.borderRadius,
    padding: theme.paddings.xs,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.paddings.xs,
  },
  separator: {
    width: '100%',
    height: '1px',
    backgroundColor: theme.palette.surface.main, // Adjust color as needed
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap', // Allows wrapping on smaller screens
  },
  title: {
    ...theme.typography.bodySmallRegular,
    '@media (min-width: 767.9px) and (max-height: 700px)': {
      fontSize: '10px !important',
    },
  },
  blockId: {
    textAlign: 'right',
    '@media (max-width: 600px)': {
      width: '100%',
      textAlign: 'left',
    },
  },
  infoGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)', // 3 columns
    // Responsive adjustments
    '@media (max-width: 900px)': {
      gridTemplateColumns: 'repeat(2, 1fr)', // 2 columns on medium screens
    },
    '@media (max-width: 600px)': {
      gridTemplateColumns: '1fr', // 1 column on small screens
    },
  },
  infoItem: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.paddings.xs,
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    flexDirection: 'column',
  },
  infoTitle: {
    ...theme.typography.infoMedium,
    color: theme.palette.steel.main,
    lineHeight: '15px',
    marginBottom: theme.paddings.xs,
    '@media (min-width: 767.9px) and (max-height: 700px)': {
      fontSize: '8px !important',
    },
  },
  infoValue: {
    ...theme.typography.infoRegular,
    color: theme.palette.steel.main,
    lineHeight: '10px',
    '@media (min-width: 767.9px) and (max-height: 700px)': {
      fontSize: '8px !important',
    },
  },
}));

export default useStyles;