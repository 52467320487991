import { useTranslation } from 'react-i18next';
import { ReactElement, useEffect, useState } from 'react';
import { postNewGrower } from 'src/reduxToolkit/thunks/grower_thunk';
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from 'react-phone-number-input';
import { Regex } from 'src/shared/constants/consts';
import {
  handleCountryValidation,
  isValidTLD,
} from 'src/shared/helpers/FormValidationHelper';
import { useUserAccess } from 'src/shared/hooks/useUserAccess';
import {
  SelectCountry,
  SelectState,
  SelectOwner,
  SelectItem,
  TextForm,
  GenericModal,
  ModalHelper,
  SelectedCheckbox,
  AdditionalEmailsField,
} from 'src/shared/components/GenericModal';
import { useAppDispatch } from 'src/reduxToolkit/store/hooks';
import notifyService from 'src/shared/notification/notification.service';
import messageTypes from 'src/consts/messages';
import countries from 'src/consts/countries';
import { UserRole } from 'src/shared/constants/enums';
import countryMap from 'src/shared/data/CountryMap';
import { NewGrower } from 'src/shared/data/types/growerFarmsTypes';
import agentsAdapter from 'src/reduxToolkit/adapters/AgentsAdapter';
import { Owner } from 'src/shared/data/types/agentOptionsTypes';

// Import the new component

function AddGrowerModal(funcProps: any): ReactElement | null {
  const { onClose, onSave } = funcProps;
  const dispatch = useAppDispatch();
  const [title, setTitle] = useState<string>('');
  const [titleValidation, setTitleValidation] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [description, setDescription] = useState<string>('');
  const [country, setCountry] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [countryValidation, setCountryValidation] = useState<boolean>(false);
  const [stateName, setStateName] = useState<string>('');
  const [stateNameValidation, setStateNameValidation] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>('');
  const [firstNameValidation, setFirstNameValidation] = useState<boolean>(false);
  const [lastName, setLastName] = useState<string>('');
  const [lastNameValidation, setLastNameValidation] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [emailValidation, setEmailValidation] = useState<boolean>(false);
  const [phone, setPhone] = useState<string>('');
  const [phoneValidation, setPhoneValidation] = useState<boolean>(false);
  const [roleValidation, setRoleValidation] = useState<boolean>(false);
  const [role, setRole] = useState<string>(UserRole.GROWER);
  const { isAdmin } = useUserAccess();
  const [ownerId, setOwnerId] = useState<string>('');
  const [owners, setOwners] = useState<Owner[]>([]);
  const [ownerEmail, setOwnerEmail] = useState<string>('');
  const [accountId, setAccountId] = useState<string | null>();
  const [sendWeeklyReport, setSendWeeklyReport] = useState<boolean>(false);

  // State for multiple emails
  const [additionalEmails, setAdditionalEmails] = useState<string[]>([]);

  const { t } = useTranslation();
  const props = {
    title,
    setTitle,
    titleValidation,
    setTitleValidation,
    description,
    setDescription,
    country,
    setCountry,
    countryValidation,
    setCountryValidation,
    stateName,
    setStateName,
    stateNameValidation,
    setStateNameValidation,
    firstName,
    setFirstName,
    firstNameValidation,
    setFirstNameValidation,
    lastName,
    setLastName,
    lastNameValidation,
    setLastNameValidation,
    email,
    setEmail,
    emailValidation,
    setEmailValidation,
    phone,
    setPhone,
    phoneValidation,
    setPhoneValidation,
    role,
    setRole,
    roleValidation,
    setRoleValidation,
    ownerId,
    setOwnerId,
    ownerEmail,
    setOwnerEmail,
    accountId,
    setAccountId,
    city,
    setCity,
    sendWeeklyReport,
    setSendWeeklyReport,
  };

  const growerFields = ModalHelper(props);
  const [growerRoleList, setGrowerRoleList] = useState<any[]>([]);

  useEffect(() => {
    if (isAdmin) {
      setGrowerRoleList([
        {
          value: UserRole.GROWER,
          label: `${t('role.grower')}`,
        },
        {
          value: UserRole.RESEARCHER_GROWER,
          label: `${t('role.researcher-grower')}`,
        },
      ]);
    } else {
      setGrowerRoleList([
        {
          value: UserRole.GROWER,
          label: `${t('role.grower')}`,
        },
      ]);
    }
  }, [isAdmin, setGrowerRoleList, t]);

  useEffect(() => {
    getOwners().then();
  }, []);

  async function getOwners() {
    const owners = await agentsAdapter.getOwners();
    setOwners(owners.data.owners);
  }

  const newGrower: NewGrower = {
    title: title,
    description: description,
    country: country,
    city: city,
    state: stateName,
    email: email,
    firstName: firstName,
    lastName: lastName,
    phone: phone,
    role: role,
    accountCognitoId: accountId!,
    ownerCognitoId: ownerId,
    ownerEmail: ownerEmail,
    sendWeeklyReport: sendWeeklyReport,
    additionalEmails: additionalEmails,
  };

  const handleSave = (e: any): void => {
    e.preventDefault();
    e.stopPropagation();
    if (loading) {
      return;
    }
    setLoading(true);
    void dispatch(postNewGrower({ newGrower: newGrower }))
        .then((res) => {
          if (res?.payload != null) {
            onSave();
            notifyService(
                t('growerFieldModal.notifyAddGrowerSuccess'),
                messageTypes.success,
            );
          }
        })
        .finally(() => {
          setLoading(false);
        });
  };

  return (
      <GenericModal
          title={t('growerFieldModal.addGrowerModalTitle')}
          onClose={onClose}
          onSave={handleSave}
          isLoading={loading}
          isSaveDisabled={
              !ownerId ||
              !title ||
              !firstName ||
              !lastName ||
              !email ||
              !phone ||
              !country ||
              !role ||
              !isValidPhoneNumber(phone) ||
              !isPossiblePhoneNumber(phone) ||
              !Regex.EMAIL_REGEX.test(email) ||
              (Regex.EMAIL_REGEX.test(email) && !isValidTLD(email)) ||
              !handleCountryValidation(country, stateName)
          }
          saveButtonText={t('growerFieldModal.addGrowerBtn')}
          cancelButtonText={t('growerFieldModal.cancel')}
      >
        <div className="Title">
          <TextForm data={growerFields.titleField} typeForm="grower" />
        </div>
        <div className="Owner">
          <SelectOwner
              owners={owners}
              onChange={(id, email, accountId) => {
                setOwnerId(id);
                setOwnerEmail(email);
                setAccountId(accountId);
              }}
              selectedOwnerId={ownerId}
          />
        </div>
        <div className="First">
          <TextForm data={growerFields.firstNameField} typeForm="grower" />
        </div>
        <div className="Last">
          <TextForm data={growerFields.lastNameField} typeForm="grower" />
        </div>
        <div className="Country">
          <SelectCountry data={growerFields.countryField} />
        </div>
        <div className="State">
          {country === countries.USA || country === countries.USAShort ? (
              <SelectState data={growerFields.stateNameField} />
          ) : (
              <TextForm data={growerFields.cityField} typeForm="grower" />
          )}
        </div>
        <div className="Phone">
          <TextForm
              data={growerFields.phoneField}
              type="phone"
              code={countryMap.get(country)}
              typeForm="grower"
          />
        </div>
        <div className="Email">
          <TextForm data={growerFields.emailField} type="email" typeForm="grower" />
        </div>
        <div className="Role">
          <SelectItem data={growerFields.roleField} items={growerRoleList} />
        </div>
        <div className="Description">
          <TextForm data={growerFields.descriptionField} typeForm="grower" />
        </div>
        {isAdmin && (
            <div className="AdminSection">
              <SelectedCheckbox data={growerFields.sendWeeklyReportField} />
              {sendWeeklyReport && (
                  <AdditionalEmailsField
                      additionalEmails={additionalEmails}
                      setAdditionalEmails={setAdditionalEmails}
                  />
              )}
            </div>
        )}
      </GenericModal>
  );
}

export default AddGrowerModal;