// src/reduxToolkit/thunks/flowDataThunks.ts

import { createAsyncThunk } from '@reduxjs/toolkit';
import flowDataAdapter from '../adapters/FlowAdapter';
import {ActualIrrigation} from "../../shared/data/types/blockDataTypes";

const getFlowData = createAsyncThunk<
    ActualIrrigation[],
    { id: number },
    { rejectValue: string }
>(
    'flowData/getFlowData',
    async ({ id }, { rejectWithValue }) => {
        try {
            const response = await flowDataAdapter.getFlowData(id);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data || 'Failed to fetch flow data');
        }
    }
);

const getUnitRealtimeIrrigation = createAsyncThunk<
    string[], // The API will return an array of IMEIs as strings
    string[], // Accept a plain string array as input
    { rejectValue: string }
>(
    'flow-data/getUnitRealtimeIrrigation',
    async (imeis, { rejectWithValue }) => {
        try {
            const response = await flowDataAdapter.getUnitRealtimeIrrigation(imeis);
            // If response.data is [{ imei: 'xxx' }, { imei: 'yyy' }], extract the strings
            const imeiStrings: string[] = response.data.map((item: { imei: string }) => item.imei);
            return imeiStrings;
        } catch (error: any) {
            return rejectWithValue(error.response?.data || 'Failed to fetch real-time irrigation data');
        }
    }
);

export { getFlowData, getUnitRealtimeIrrigation };