import { alpha, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: '100%',
  },
  box: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    backgroundColor: '#fff',
    opacity: '0.9',
    width: 'calc(100% - 1.6rem)',
    position: 'absolute',
    top: '10px',
    left: '0.8rem',
    borderRadius: '4px',
    zIndex: 10,
    '&:hover': {
      opacity: '1',
    },
    // Mobile Styles
    '@media (max-width: 767px)': {
      flexDirection: 'column',
      width: '90%',
      left: '5%',
      top: '5px',
      padding: '0.5rem',
    },
  },
  boxActive: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    backgroundColor: '#fff',
    opacity: '1',
    width: 'calc(100% - 1.6rem)',
    position: 'absolute',
    top: '10px',
    left: '0.8rem',
    borderRadius: '4px',
    zIndex: 10,
    // Mobile Styles
    '@media (max-width: 767px)': {
      flexDirection: 'column',
      width: '90%',
      left: '5%',
      top: '5px',
      padding: '0.5rem',
    },
  },
  sensorBox: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    backgroundColor: '#fff',
    width: '95%',
    position: 'absolute',
    top: '20px',
    left: '2.5%',
    borderRadius: '4px',
    zIndex: 10,
  },
  headerNavigation: {
    paddingInline: '0',
    width: '100%',
    display: 'flex',
    gap: '2rem',
    justifyContent: 'space-around',
    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.accent.main,
      top: '22px',
    },
    '& .MuiTabs-flexContainer': {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
    },
    '&.MuiTabs-root': {
      width: '100%',
    },
    '& > div:nth-child(1) > img': {
      marginInlineEnd: '-2px',
    },
    // Mobile Styles
    '@media (max-width: 767px)': {
      gap: '1rem',
    },
  },
  tabs: {
    '&:hover': {
      color: theme.palette.accent.main,
      opacity: 1,
    },
    '&.MuiButtonBase-root': {
      textTransform: 'none',
      padding: '0',
      margin: '0',
      minWidth: '70px',
      minHeight: '0',
    },
    '&.MuiButtonBase-root.Mui-selected': {
      fontWeight: '700',
    },
    // Mobile Styles
    '@media (max-width: 767px)': {
      minWidth: '50px',
      fontSize: '0.9rem',
    },
  },
  rightSide: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0',
    marginRight: '1rem',
    marginLeft: 'auto',
    whiteSpace: 'nowrap',
    // Mobile Styles
    '@media (max-width: 767px)': {
      marginRight: '0',
      marginLeft: '0',
      marginTop: '0.5rem',
      width: '100%',
      justifyContent: 'center',
    },
  },
  closedState: {
    width: '100%',
    padding: '0.6rem 1rem',
    gap: '2%',
    '& li': {
      alignItems: 'center',
    },
    '& li:first-child': {
      alignItems: 'flex-start',
      fontSize: 'calc(1rem + 0.4vw)',
      whiteSpace: 'nowrap',
      '& div': {
        '& span': {
          fontSize: 'calc(1rem + 0.8vw) !important',
          '@media (min-width: 767.9px) and (max-height: 700px)': {
            fontSize: '10px !important',
          },
        },
        '@media (min-width: 1240px)': {
          '& img': {
            marginInlineEnd: '1rem',
          },
        },
      },
      '& p': {
        color: theme.palette.oldPrimary.main,
        fontSize: 'calc(0.6rem + 0.6vw) !important',
        margin: '0',
      },
    },
    '@media (min-width: 767.9px) and (max-height: 700px)': {
      padding: '0.1rem 0.1rem',
    },
  },
  closeIcon: {
    paddingInline: '1rem 0.2rem',
    paddingBlock: '0 !important',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    height: '1rem !important',
    lineHeight: 'unset !important',
    '& img': {
      width: '1rem',
      margin: 0,
    },
    '&:hover': {
      backgroundColor: alpha(theme.palette.oldPrimary.main, 0.1),
    },
  },
  closeIconOpen: {
    '@media (max-width: 767px)': {
      bottom: '18px', // Move further up on mobile when open
    },
  },
  openState: {
    width: '100%',
    padding: '0.5rem 1rem',
    // Mobile Styles
    '@media (max-width: 767px)': {
      padding: '0.5rem 0.5rem',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  backArrow: {
    maxWidth: '30px',
    '& svg': {
      '& path': {
        fill: theme.palette.accent.main,
      },
    },
    // Mobile Styles
    '@media (max-width: 767px)': {
      maxWidth: '25px',
    },
  },
  date: {
    paddingLeft: '24px',
    paddingRight: '24px',
    color: theme.palette.oldPrimary.main,
    // Mobile Styles
    '@media (max-width: 767px)': {
      paddingLeft: '12px',
      paddingRight: '12px',
      fontSize: '0.9rem',
    },
  },
  forwardArrow: {
    maxWidth: '30px',
    // Mobile Styles
    '@media (max-width: 767px)': {
      maxWidth: '25px',
    },
  },
  t2sLogo: {
    display: 'flex',
    height: '50px',
    width: '45px',
    justifyContent: 'center',
    // Mobile Styles
    '@media (max-width: 767px)': {
      height: '40px',
      width: '35px',
    },
  },
  username: {
    margin: '0',
    // Mobile Styles
    '@media (max-width: 767px)': {
      fontSize: '0.9rem',
    },
  },
  vl: {
    borderLeft: '2px solid rgba(103, 104, 121, 0.25)',
    height: '-webkit-fill-available',
    marginInline: '1rem',
    // Mobile Styles
    '@media (max-width: 767px)': {
      display: 'none',
    },
  },
  mapSettingsTop: {
    position: 'absolute',
    left: '10px',
    top: '10px',
    width: '40px',
    '& > button': {
      paddingBlock: '0.4rem 0.6rem !important',
      paddingInline: '0.5rem 0 !important',
      minWidth: 'fit-content !important',
      height: 'fit-content !important',
      background: '#fff !important',
      borderRadius: '4px !important',
      boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 0px 2px !important',
      outline: 'none!important',
      border: 'none!important',
      opacity: '0.9',
      '& > div': {
        '& > div': {
          gap: '0.5rem !important',
          marginBlockEnd: '-0.25rem',
          '& > img': {
            height: '1.6rem',
          },
          '& p': {
            color: theme.palette.oldPrimary.main,
            margin: 'unset !important',
            fontWeight: 700,
            fontSize: 'calc(0.7rem + 0.6vw) !important',
            whiteSpace: 'nowrap !important',
            alignItems: 'flex-start !important',
          },
        },
        '& p': {
          color: theme.palette.oldPrimary.main,
          whiteSpace: 'nowrap !important',
          gap: '0.2rem !important',
          fontSize: 'calc(0.7rem + 0.6vw) !important',
          margin: 'unset !important',
        },
      },
      '&:focus': {
        outline: 'none!important',
        border: 'none!important',
      },
      '&:active': {
        outline: 'none!important',
        border: 'none!important',
      },
      '&:hover': {
        outline: 'none!important',
        border: 'none!important',
        opacity: '1',
      },
      '@media (max-width: 767px)': {
        paddingBlock: '0.3rem 0.4rem !important',
        paddingInline: '0.3rem 0 !important',
        '& > div': {
          '& > div': {
            gap: '0.3rem !important',
            '& > img': {
              height: '1.4rem',
            },
            '& p': {
              fontSize: '0.8rem !important',
            },
          },
          '& p': {
            fontSize: '0.8rem !important',
          },
        },
      },
    },
    // Mobile Styles
    '@media (max-width: 767px)': {
      width: '35px',
      left: '5px',
      top: '5px',
    },
  },
}));

export default useStyles;