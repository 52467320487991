import React from 'react';
import { IconFile } from "../../../../assets/Icons/blockData";

interface IconProps {
    name: keyof typeof IconFile; // Ensure the key matches IconFile keys
    height?: number; // Optional size prop for controlling the icon height
    width?: number;  // Optional size prop for controlling the icon width
    onClick?: () => void; // Optional onClick prop for handling clicks
}

const GenericImg: React.FC<IconProps> = ({ name, width = 24, height = 24, onClick }) => {
    const iconSrc = IconFile[name];

    if (!iconSrc) {
        return null; // Return null if the icon doesn't exist
    }

    return (
        <img
            src={iconSrc}
            width={width}
            height={height}
            style={{
                display: 'block',
                margin: 0,
                padding: 0,
                lineHeight: 0,
                border: 'none'
            }}
            onClick={onClick}
            alt={name}
        />
    );
};

export default GenericImg;