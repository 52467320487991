// blocksAdapter.ts

import { AxiosResponse } from 'axios';
import { HttpClientService } from 'src/services/HttpClientService';
import { BlockActualConsumptionGraphType } from 'src/shared/data/types/ETConsumptionGraphTypes';
import { BlockType } from 'src/shared/data/types/blockTypes';
import { FormBlock } from 'src/shared/data/types/formsTypes';
import { MiniBlock } from '../../shared/data/types/growerFarmsTypes';

const accessToken = '';

class BlocksAdapter {
  static readonly endpoint = `/blocks`;

  async getBlocksByFarm(
      farmId: number,
      daysBack: number,
  ): Promise<AxiosResponse<BlockType[]>> {
    return await HttpClientService.get<BlockType[]>(
        `${BlocksAdapter.endpoint}/farm-blocks-data/${farmId}/${daysBack}`,
        {
          headers: { accessToken },
        },
    );
  }

  async getFarmBlocksConsumptionGraphData(
      farmId: number,
      daysBack: number,
  ): Promise<AxiosResponse<BlockActualConsumptionGraphType[]>> {
    return await HttpClientService.get<BlockActualConsumptionGraphType[]>(
        `${BlocksAdapter.endpoint}/farm-blocks-actual-consumption/${farmId}/${daysBack}`,
        {
          headers: { accessToken },
        },
    );
  }

  async getNewBlockETcForecast(
      id: number,
  ): Promise<AxiosResponse<BlockType[]>> {
    return await HttpClientService.get<BlockType[]>(
        `${BlocksAdapter.endpoint}/block-forecast-etc/${id}`,
        {
          headers: { accessToken },
        },
    );
  }

  async getBlockETcForecasts(
      farmId: number,
  ): Promise<AxiosResponse<BlockType[]>> {
    return await HttpClientService.get<BlockType[]>(
        `${BlocksAdapter.endpoint}/farm-blocks-forecast-etc/${farmId}`,
        {
          headers: { accessToken },
        },
    );
  }

  async getBlockInfoOptions(): Promise<AxiosResponse<any>> {
    return HttpClientService.get<any>(`${BlocksAdapter.endpoint}/pageData/`, {
      headers: {
        accessToken,
      },
    });
  }

  async createNewBlock(
      block: FormBlock,
      newPolygonPathAfterEdit: any,
      area?: number,
      farmId?: number,
  ): Promise<AxiosResponse<any>> {
    const formData = new FormData();

    // Collect block properties, including new fields
    const blockProps: { [key: string]: any } = {
      title: block.title,
      cropTypeId: block.cropTypeId,
      cropVarietyId: block.cropVarietyId,
      treeSpacing: block.treeSpacing,
      rowSpacing: block.rowSpacing,
      plantingYear: block.plantingYear,
      soilTextureId: block.soilTextureId,
      irrigationSystemId: block.irrigationSystemId,
      farmId: block.farmId,
      area: block.area,
      systemApplicationRate: block.systemApplicationRate,
      numberOfIrrigationPipes: block.numberOfIrrigationPipes, // New field
      distanceBetween: block.distanceBetween,                 // New field
      flowRatio: block.flowRatio,                             // New field
      zonalDataJson: block.zonalDataJson,
      sensors: block.sensors,
      installers: block.installers,
    };

    Object.entries(blockProps).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        if (key === 'zonalDataJson') {
          formData.append(key, value);
        } else if (key === 'installers') {
          formData.append('installers', value);
        } else if (key === 'sensors') {
          // Sensors are handled separately
        } else {
          formData.append(key, String(value));
        }
      }
    });

    formData.append('polygon', JSON.stringify(newPolygonPathAfterEdit));
    if (area !== undefined) {
      formData.append('area', String(area));
    }
    if (farmId !== undefined) {
      formData.append('farmId', String(farmId));
    }

    const res = await HttpClientService.post<any>(
        `${BlocksAdapter.endpoint}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            accessToken,
          },
        },
    );

    const createdBlock = res.data;
    if (block.sensors && block.sensors.length > 0) {
      const connectSensorToBlockPromises = block.sensors.map(async (sensorId) =>
          HttpClientService.put<any>(`/units/${sensorId}`, {
            blockId: createdBlock.id,
          }),
      );
      await Promise.all(connectSensorToBlockPromises).catch((err) =>
          console.error(err),
      );
    }
    return res;
  }

  async deleteBlockInfo(block: MiniBlock): Promise<AxiosResponse<any>> {
    return HttpClientService.delete<any>(
        `${BlocksAdapter.endpoint}/${block.id}`,
        {},
    );
  }

  async updateBlockInfo(block: FormBlock): Promise<AxiosResponse<any>> {
    const formData = new FormData();

    const blockProps: { [key: string]: any } = {
      title: block.title,
      cropTypeId: block.cropTypeId,
      cropVarietyId: block.cropVarietyId,
      treeSpacing: block.treeSpacing,
      rowSpacing: block.rowSpacing,
      plantingYear: block.plantingYear,
      soilTextureId: block.soilTextureId,
      irrigationSystemId: block.irrigationSystemId,
      farmId: block.farmId,
      area: block.area,
      systemApplicationRate: block.systemApplicationRate,
      numberOfIrrigationPipes: block.numberOfIrrigationPipes, // New field
      distanceBetween: block.distanceBetween,                 // New field
      flowRatio: block.flowRatio,                             // New field
      zonalDataJson: block.zonalDataJson,
      sensors: block.sensors,
      installers: block.installers,
    };

    Object.entries(blockProps).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        if (key === 'zonalDataJson') {
          formData.append(key, value as string | Blob);
        } else if (key === 'installers') {
          formData.append('installers', value);
        } else if (key === 'sensors') {
          // Sensors are handled separately
        } else {
          formData.append(key, String(value));
        }
      }
    });

    if (block.sensors && block.sensors.length > 0) {
      const connectSensorToBlockPromises = block.sensors.map(async (sensorId) =>
          HttpClientService.patch<any>(`/units/${sensorId}`, {
            blockId: block.id,
          }),
      );
      await Promise.all(connectSensorToBlockPromises).catch((err) =>
          console.error(err),
      );
    }
    return HttpClientService.put<any>(
        `${BlocksAdapter.endpoint}/${block.id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            accessToken,
          },
        },
    );
  }
}

const blocksAdapter = new BlocksAdapter();
export default blocksAdapter;