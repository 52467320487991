import Sprinkler from './sprinkler.svg';
import Tree from './tree.svg';
import WaterFull from './water_full.svg';
import ChevronRight from './chevron_right.svg';
import Close from './close.svg';
import GreenTree from './green_tree.svg';
import GreenDrop from './green_drop.svg';
import GreenSprinkler from './green_sprinkler.svg';
import GreenDownload from './green_download.svg';
import CalendarToday from './calendar_today.svg';
import Add from './add.svg';
import ChevronDown from './chevron_down.svg';
import Edit from './edit.svg';
import Download from './download.svg';
import Search from './search.svg';
import GreenTreeGraph from './green_tree_graph.svg';
import WaterFullGraph from './water_full_graph.svg';
import ChevronUp from './chevron_up.svg';
import Almond from './almond.svg';
import Apple from './apple.svg';
import Avocado from './avocado.svg';
import Cherry from './cherry.svg';
import Clementine from './clementine.svg';
import ForestTree from './forest_tree.svg';
import GeneralCrop from './general_crop.svg';
import Hazelnut from './hazelnut.svg';
import Kiwi from './kiwi.svg';
import Lemon from './lemon.svg';
import Olive from './olive.svg';
import Peach from './peach.svg';
import Pecan from './pecan.svg';
import Pistachio from './pistachio.svg';
import TableGrape from './tablegrape.svg';
import UrbanTree from './urban_tree.svg';
import Walnut from './walnut.svg';
import Citrus from './citrus.svg';
import Mango from './mango.svg';
import DownloadRed from './download_red.svg';
import TreeRed from './tree_red.svg';
import SprinklerRed from './sprinkler_red.svg';
import WaterFullRed from './water_full_red.svg';
import irrigationOn from './irrigation_on.svg';
import irrigationOff from './irrigation_off.svg';
import irrigationOnBlock from './irrigation_on_block.svg';
import sap from './sap.svg';
import irrigationTag from './irrigation_tag.svg';

export const IconFile = {
  sprinkler: Sprinkler,
  tree: Tree,
  water_full: WaterFull,
  chevron_right: ChevronRight,
  close: Close,
  green_tree: GreenTree,
  green_drop: GreenDrop,
  green_sprinkler: GreenSprinkler,
  green_download: GreenDownload,
  calendar_today: CalendarToday,
  add: Add,
  chevron_down: ChevronDown,
  edit: Edit,
  download: Download,
  search: Search,
  greenTreeGraph: GreenTreeGraph,
  waterFullGraph: WaterFullGraph,
  chevron_up: ChevronUp,
  kiwi:Kiwi,
  cherry:Cherry,
  mango:Mango,
  urban_tree:UrbanTree,
  almond:Almond,
  walnut:Walnut,
  pistachio:Pistachio,
  pummelo:Citrus,
  avocado:Avocado,
  olive:Olive,
  orange:Clementine,
  forest_tree:ForestTree,
  wine_grape:TableGrape,
  table_grape:TableGrape,
  pecan:Pecan,
  peach:Peach,
  lemon:Lemon,
  grapefruit:Citrus,
  hazelnut:Hazelnut,
  apple:Apple,
  clementine:Clementine,
  general_crop:GeneralCrop,
  download_red:DownloadRed,
  tree_red:TreeRed,
  sprinkler_red:SprinklerRed,
  water_full_red:WaterFullRed,
  irrigation_on:irrigationOn,
  irrigation_off:irrigationOff,
  irrigation_on_block:irrigationOnBlock,
  sap:sap,
  irrigation_tag:irrigationTag
};