import React from 'react';
import copyIcon from 'src/assets/Icons/sharedIcons/copy.svg';
import {smallMaxWidth, xSmallMaxHeight} from "../../constants/consts";
import {useWindowDimensions} from "../../hooks/useWindowDimensions";

interface IconProps {
    onClick?: (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
    onKeyDown?: (event: React.KeyboardEvent<HTMLImageElement>) => void;
    tabIndex?: number;
}

function CopyIcon({ onClick, onKeyDown, tabIndex = 0 }: IconProps): JSX.Element {
    const { width, height } = useWindowDimensions();
    const zoom = smallMaxWidth < width  && xSmallMaxHeight > height
    return (
        <img
            src={copyIcon}
            alt="Copy"
            style={{ paddingRight: '6px', cursor: 'pointer'}}
            height= {zoom ? "10px" : "20px"}
            onClick={onClick}
            onKeyDown={onKeyDown}
            tabIndex={tabIndex}
        />
    );
}

export default CopyIcon;
