import { darken, Theme, alpha } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  fieldsPageContainer: {
    minWidth: '100%',
    flex: 1,
  },
  rootNewBlock: {
    minWidth: '100%',
    minHeight: '100%',
    backgroundColor: '#F5F5F5',
    overflow: 'hidden',
    flex: 1,
  },
  root: {
    flex: 1,
    minWidth: '100%',
    backgroundColor: '#F5F5F5',
    gridTemplateColumns: '20vw 80vw',
    gridTemplateRows: '50vh auto',
    gridTemplateAreas: `"blockList mapAndInfoContainer" "blockInfo blockInfo"`,
    maxWidth: '100vw',
    '@media (max-width: 767.9px)': {
      display: 'contents',
    },
  },
  hoverOnLayers: {
    borderRadius: '4px',
    position: 'relative',
    height: '30px',
    width: '30px !important',
    minWidth: 'unset !important',
    overflow: 'hidden',
    zIndex: 7,
    padding: 'unset !important',
    backgroundColor: `${darken(theme.palette.oldPrimary.main, 0.4)}!important`,
    border: '0.02em solid #ffffff54 !important',
    '& button': {
      width: '30px !important',
      minWidth: 'unset !important',
      height: '30px',
      padding: 'unset !important',
      justifyContent: 'center',
      '&:focus': {
        outline: 'none!important',
      },
      '&:hover': {
        background: `${theme.palette.hoverDarkGrey.main}!important`,
      },
      '& > img': {
        filter: 'brightness(0) invert(1) opacity(0.85) !important',
      },
      '@media (min-width: 767.9px) and (max-height: 700px)': {
        width: '30px !important',
        height: '30px',
        '@media (max-width: 767px)': {
          width: '20px !important',
          height: '20px',
        },
      },
    },
    '@media (min-width: 767.9px) and (max-height: 700px)': {
      width: '30px !important',
      height: '30px',
      '@media (max-width: 767px)': {
        width: '20px !important',
        height: '20px',
      },
    },
  },
  configMapSettingsContainer: {
    position: 'absolute',
    top: '20px', // Distance from the top of the map
    right: '20px', // Changed from 'left' to 'right' to move it to the right side
    display: 'flex',
    flexDirection: 'column',
    zIndex: 1, // Ensure buttons are above the map
    '& > li:not(:first-child) button': {
      background: `${darken(theme.palette.oldPrimary.main, 0.4)}!important`,
      color: '#fff!important',
      whiteSpace: 'nowrap !important',
      borderRadius: '4px !important',
      '& > img': {
        filter: 'brightness(0) invert(1) opacity(0.85) !important',
      },
      '&:hover': {
        background: `${darken(theme.palette.hoverDarkGrey.main, 0.5)}!important`,
      },
    },
    '@media (max-width: 767px)': {
      top: '12px', // Distance from the top of the map
    },
  },
  configMapSettings: {
    marginTop: '-7.5rem',
    position: 'absolute',
    border: 0,
    left: '0.5rem',
    minWidth: '40px',
    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 0px 2px',
    '& > button': {
      background: `${darken(theme.palette.oldPrimary.main, 0.4)}!important`,
      borderRadius: '4px !important',
      '& > img': {
        filter: 'brightness(0) invert(1) opacity(0.85) !important',
      },
      '&:hover': {
        background: `${darken(
          theme.palette.hoverDarkGrey.main,
          0.5,
        )}!important`,
      },
    },
    '&:hover': {
      background: `${darken(theme.palette.hoverDarkGrey.main, 0.5)}!important`,
    },
  },
  mapSettingsTop: {
    position: 'absolute',
    left: '13px !important',
    top: '8px !important',
    '& > button': {
      paddingBlock: '0.2rem 0.3rem !important',
      paddingInline: '0.5rem 0 !important',
      minWidth: 'fit-content !important',
      height: 'fit-content !important',
      background: '#fff !important',
      borderRadius: '4px !important',
      boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 0px 2px !important',
      outline: 'none!important',
      border: 'none!important',
      opacity: '0.9',
      '& > div': {
        '& > div': {
          gap: '0.5rem !important',
          marginBlockEnd: '-0.25rem',
          '&>img': {
            height: '1.6rem',
            '@media (min-width: 767.9px) and (max-height: 700px)': {
              height: '0.9rem',
            }
          },
          '& p': {
            color: theme.palette.oldPrimary.main,
            margin: 'unset !important',
            fontWeight: 700,
            fontSize: '15px !important',
            whiteSpace: 'nowrap !important',
            alignItems: 'flex-start !important',
            '@media (min-width: 767.9px) and (max-height: 700px)': {
              fontSize: '10px !important',
            }
          },
        },
        '& p': {
          color: theme.palette.oldPrimary.main,
          whiteSpace: 'nowrap !important',
          gap: '0.2rem !important',
          fontSize: 'calc(0.7rem + 0.6vw) !important',
          margin: 'unset !important',
          '@media (min-width: 767.9px) and (max-height: 700px)': {
            fontSize: '10px !important',
          }},
      },
      '&:focus': {
        outline: 'none!important',
        border: 'none!important',
      },
      '&:active': {
        outline: 'none!important',
        border: 'none!important',
      },
      '&:hover': {
        outline: 'none!important',
        border: 'none!important',
        opacity: '1',
      },
      '@media (min-width: 767.9px) and (max-height: 700px)': {
        height: '0.6rem',
      }
    },
    '@media (min-width: 767.9px) and (max-height: 700px)': {
      position: 'absolute',
      left: '13px !important',
      top: '8px !important',
      '& > button': {
        paddingBlock: '0.1rem 0.2rem !important',
        paddingInline: '0.3rem 0 !important',
        minWidth: 'fit-content !important',
        height: 'fit-content !important',
        background: '#fff !important',
        borderRadius: '4px !important',
        boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 0px 2px !important',
        outline: 'none!important',
        border: 'none!important',
        opacity: '0.9',
      },

    },
  },
  forwardArrow: {
    alignSelf: 'center',
    border: 'none',
    backgroundColor: 'transparent',
    outline: 'none',
    cursor: 'pointer',
    width: '1.9rem',
    minWidth: 'unset !important',
    gap: '0.5rem !important',
    marginBlockEnd: '-0.4rem',
    '& img': {
      height: '0.8rem', // Set the height for the img element
      '@media (min-width: 767.9px) and (max-height: 700px)': {
        height: '0.6rem', // Set the height for the img element
      }
    },
    '@media (min-width: 767.9px) and (max-height: 700px)': {
      width: '1.4rem',
      gap: '0.3rem !important',
      marginBlockEnd: '-0.2rem',
    }
  },
  mapSettings: {
    paddingLeft: '0.5rem',
    position: 'absolute',
    left: 'unset !important',
    bottom: '1.7rem !important',
    '& > button': {
      background: `${darken(theme.palette.oldPrimary.main, 0.4)}`,
      borderRadius: '4px !important',
      border: '0.02em solid #ffffff54 !important',
      '& .active': {
        border: '0.05em solid #ffffff9c!important',
      },
      '&:focus': {
        border: '0.05em solid #ffffff9c!important',
        outline: 'none!important',
      },
      '&:hover': {
        background: `${darken(theme.palette.hoverDarkGrey.main, 0.5)}`,
        borderRadius: '4px !important',
        border: '0.05em solid #ffffff9c!important',
      },
      '& > img': {
        filter: 'brightness(0) invert(1) opacity(0.85) !important',
      },
      '@media (min-width: 767.9px) and (max-height: 700px)': {

      }
    },
    '@media (min-width: 767.9px) and (max-height: 700px)': {
      paddingLeft: '0.5rem',
    }
  },
  mapSettingsBtn: {
    marginLeft: '0.2rem!important',
    border: 0,
    fontWeight: '600',
    height: '30px !important',
    width: '20px !important',
    fontSize: '0.5rem !important',
    minWidth: '30px !important',
    '& img': {
      height: '20px !important',
      width: '20px !important',
    },
  },
  mapResetBtnHighlight: {
    padding: '0.5rem 1rem',
    marginLeft: '0.25rem!important',
    border: 0,
    fontWeight: '600',
    height: '40px',
    width: '40px!important',
    fontSize: '0.5rem!important',
    minWidth: '40px!important',
    color: '#fff!important',
    background: 'unset !important',
    backgroundColor: `${theme.palette.accent.main}!important`,
    '&:hover': {
      background: 'unset !important',
      backgroundColor: `${darken(theme.palette.accent.main, 0.3)}!important`,
    },
  },
  blockList: {
    '@media (max-width: 767px)': {
      width: '100%',
    },
  },
  mapAndInfoContainer: {
    gridArea: 'mapAndInfoContainer',
    paddingBottom: theme.paddings.s,

    height: '100%', // Add this to ensure it occupies the full height of the grid area
  },
  map: {
    position: 'relative',
    height: '100%', // Add this to ensure it occupies the full height of the grid area
    padding: theme.paddings.m,
    zIndex: 102, // Add this line
    paddingBottom:0,
    '& > div:nth-child(1) > div:nth-child(1) > div > div': {
      '&:nth-child(12) > div > div > div > button:nth-child(3)': {
        marginTop: '0.3rem !important',
      },
      '&:nth-child(12)': {
        display: 'none !important',
      },
      '&:nth-child(14) .gmnoprint:not(:last-child)': {
        margin: 'unset !important',
        bottom: '7.6rem !important',
        flexDirection: 'column-reverse !important',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '0.2rem !important',
        '& div': {
          borderRadius: '4px !important',
          border: '0.05em solid #ffffff9c!important',
          marginRight: '0.5rem',
          '& button': {
            width: '40px',
            height: '40px',
            '& span div': {
              width: '18px !important',
              marginLeft: '0.5rem !important',
              marginTop: '-25px !important',
              border: '0.02em solid #ffffff54 !important',
              boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 0px 2px',
              '& .active': {
                border: '0.05em solid #ffffff9c!important',
              },
              '&:focus': {
                border: '0.05em solid #ffffff9c!important',
                outline: 'none!important',
              },
              '&:hover': {
                borderRadius: '4px !important',
                border: '0.05em solid #ffffff9c!important',
                background: `${darken(
                  theme.palette.hoverDarkGrey.main,
                  0.5,
                )}!important`,
              },
              '&>img': {
                width: 'unset !important',
                height: 'unset !important',
                filter: 'brightness(0) invert(1) opacity(0.85) !important',
              },
            },
          },
        },
      },
      '&:nth-child(16)': {
        display: 'flex !important',
        flexDirection: 'row-reverse !important',
        '& > div.gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom': {
          right: '0 !important',
          marginRight: '50px !important',
          marginTop: '100px !important',
          bottom: 'unset !important',
          '& > div': {
            left: 'unset !important',
            top: 'unset !important',
            '& > div': {
              display: 'flex !important',
              flexDirection: 'column !important',
              gap: '5px !important',
              background: 'unset !important',
              width: 'unset !important',
              height: 'unset !important',
              '& > button': {
                background: `${darken(
                  theme.palette.oldPrimary.main,
                  0.4,
                )}!important`,
                position: 'unset !important',
                border: '0.02em solid #ffffff54 !important',
                boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 0px 2px',
                '& .active': {
                  border: '0.05em solid #ffffff9c!important',
                },
                '&:focus': {
                  border: '0.05em solid #ffffff9c!important',
                  outline: 'none!important',
                },
                '&:hover': {
                  borderRadius: '4px !important',
                  border: '0.05em solid #ffffff9c!important',
                  background: `${darken(
                    theme.palette.hoverDarkGrey.main,
                    0.5,
                  )}!important`,
                },
                '& > img': {
                  top: 'unset !important',
                },
              },
              '& div': {
                display: 'none !important',
              },
            },
          },
        },
        '@media (max-width: 767px)': {
          display: 'none !important',
        },
      },
    },
    '@media (max-width: 767px)': {
      padding: theme.paddings.s,
      paddingBottom:0,
},
  },

  blockInfo: {
    gridArea: 'blockInfo',
    backgroundColor: '#F5F5F5',
    zIndex: 50,
  },
  mobileBlockInfo: {
    backgroundColor: '#F5F5F5',
    width: '100%',
    height: '100%',
    flex: 1,
    gap: '1rem',
    scrollBehavior: 'smooth',
    overflowY: 'scroll',
    // scrollY: "scroll",
    '&::-webkit-scrollbar': {
      width: '0.5rem !important',
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: alpha(theme.palette.oldPrimary.main, 0.2),
      borderRadius: '20px',
      border: '3px solid #fff',
    },
    '@media (-webkit-device-pixel-ratio: 1.25)': {
      zoom: 0.8,
    },
  },
  addBlockContainer: {
    width: '100%',
    height: '100%',
    paddingInlineEnd: '0.5rem',
    paddingBlock: '0 0.5rem',
    '& p': {
      textAlign: 'left',
      margin: 0,
      opacity: 0.7,
      fontSize: 'calc(0.6rem + 0.4vw)',
      fontWeight: 700,
      lineHeight: 'normal',
      color: '#676879',
      marginBlock: '0.6rem 0',
      paddingInlineStart: '1rem',
    },
  },
  noBlockFarmInfo: {
    marginBlockStart: '0.5rem',
    padding: '0.8rem',
    flex: '1',
    background: '#fff',
    borderRadius: '15px',
    '@media (max-width: 767px)': {
      padding: '1.25rem',
    },
  },
  noBlockFarmContainer: {
    width: '100%',
    textAlign: 'center',
  },
  addBlockBtn: {
    position: 'relative',
    margin: '0 auto',
    padding: '0.4rem 0.8rem',
    backgroundColor: theme.palette.accent.main,
    fontWeight: '500',
    color: '#fff',
    border: 'none',
    cursor: 'pointer',
    fontSize: 'calc(1.2rem + 0.4vw)',
    borderRadius: '0.5rem',
    '&:hover': {
      backgroundColor: darken(theme.palette.accent.main, 0.1),
    },
  },
  btnHidden: {
    display: 'none',
    visibility: 'hidden',
    backgroundColor: 'transparent',
  },
  modalOverlay: {
    minWidth: '100vw',
    minHeight: '100vh',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 100,
  },
  addFarmContainer: {
    width: '100%',
    height: '100%',
    paddingInlineEnd: '0.5rem',
    paddingBlock: '0 0.5rem',
  },
  addFarmBtn: {
    position: 'relative',
    margin: '0 auto',
    padding: '0.4rem 0.8rem',
    backgroundColor: `${theme.palette.accent.main} !important`,
    fontWeight: '500',
    color: '#fff',
    border: 'none',
    cursor: 'pointer',
    fontSize: 'calc(1.2rem + 0.4vw)',
    borderRadius: '0.5rem',
    '& > img': {
      filter: 'brightness(0) invert(1) opacity(0.85) !important',
    },
    '& p': {
      textAlign: 'center',
      margin: 'unset !important',
      fontSize: 'calc(0.8rem + 0.6vw)',
      fontWeight: 500,
      color: '#fff',
      paddingInline: '1rem',
      textTransform: 'capitalize',
    },
    '&:hover': {
      backgroundColor: `${darken(theme.palette.accent.main, 0.1)} !important`,
    },
  },
}));

export default useStyles;
