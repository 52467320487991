import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) => ({
    selectDropdown: {
        '& .ant-select-item-option': {
            ...theme.typography.bodyRegular,
            color: theme.palette.onBorder.main,

            '&.ant-select-item-option-selected': {
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.surfaceVariant.main,

                '&:hover': {
                    color: theme.palette.primary.main,
                    backgroundColor: 'transparent',
                },
            },
            '&:hover': {
                color: theme.palette.onSurface.main,
                backgroundColor: 'transparent',
            },
        },
    },
    selectBorder: {

        '&.ant-select .ant-select-selector': {
            borderRadius: theme.borderRadius.s,
            borderColor: `${theme.palette.onBorder.main} !important`,
        },
        '&.ant-select-open .ant-select-selector': {
            borderColor: `${theme.palette.onBorder.main} !important`, // Keep the primary color when dropdown is open
        },
    },
}));

export default useStyles;
