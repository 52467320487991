import countries from 'src/consts/countries';
import { codeDomainExt } from 'src/shared/data/commonEmailDomains';

export const Regex = {
  EMAIL_REGEX: /^[^\s@]+@[a-z0-9-]+(?:\.[a-z]{2,7})+$/i,
} as const;

export const handleCountryValidation = (
  country: string,
  state: string,
): boolean => {
  return (
    (country !== countries.USA && country !== countries.USAShort) ||
    ((country === countries.USA || country === countries.USAShort) && !!state)
  );
};

const codeDomainSet = new Set(codeDomainExt);

export const isValidTLD = (fieldState: string): boolean => {
  const splitDomain = fieldState?.split('@')[1]?.split('.');
  const tld = splitDomain?.pop() ?? '';
  const validTLD = codeDomainSet.has(tld);

  return validTLD;
};
