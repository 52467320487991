// useStyles.ts (Updated)
import { alpha, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
    (theme: Theme) => ({
      searchBar: {
        background: '#fff',
        height: '32px',
        minWidth: '100%',
        borderRadius: '4px',
        overflowX: 'hidden',
      },
      searchBarInput: {
        fontWeight: '400',
        backgroundColor: theme.palette.surfaceWhite.main,
        borderRadius: theme.borderRadius.s,
        paddingInline: '2%',
        gap: '3%',
        flex: '1',
        border: '1px solid',
        borderColor: `${theme.palette.onBorder.main} !important`, // Force consistent border color
        '& input': {
          padding: '2px 16px',
          border: 'none !important',
          fontSize: '16px !important', // Directly set font size
        },
        '& .ant-input': {
          textAlign: 'left',  //
          backgroundColor: '#fff', // Corrected color code
          width: '100%',
          minHeight: '-webkit-fill-available',
          boxShadow: 'none !important',
          border: 'none !important',
          outline: 'none !important',
          webkitAppearance: 'none !important',
          mozAppearance: 'none !important',
          appearance: 'none !important',
        },
        '& .ant-input::placeholder': {
          ...theme.typography.infoRegular,
          color: theme.palette.surfaceMenu.main,
          marginLeft: 'auto',
        },
        '& .ant-input-suffix': {
          padding: '0 4px',
        },
        // Override hover and focus states using !important
        '&:hover, &:focus, &:active': {
          borderColor: `${theme.palette.onBorder.main} !important`, // Ensure border color remains the same on hover
        },
        '&:hover .ant-input, &:focus .ant-input': {
          borderColor: 'none !important', // Ensure input itself does not change border color
          boxShadow: 'none !important', // Prevent any shadow changes on hover/focus
        },
      },
      '@media (max-width: 767px)': {
        searchBar: {
          height: '40px',
        },
        searchBarInput: {
          '& input': {
            fontSize: '16px !important', // Ensure font size is applied
            padding: '4px 16px', // Adjust padding if necessary
          },
        },
      },
    }),
    { name: 'SearchBar' },
);

export default useStyles;