import agentsIcon from 'src/assets/Icons/sharedIcons/agents-list.svg';
import './fieldsIcon.css';

function FieldsIcon(): JSX.Element {
  return (
    <img
      src={agentsIcon}
      alt=""
      style={{ paddingRight: '0.7rem' }}
      className="fields-icon"
    />
  );
}

export default FieldsIcon;
