// src/reduxToolkit/slices/UserSlice.ts

import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import {
  MeasurementSystem,
  User,
  UserMeasurementSystem,
  UserDepthVolume,
  UserValueTime,
} from '../../../shared/data/types/UserTypes';
import { getUserDetails, updateUserGrowerId } from '../../thunks/user_thunks';
import { DepthVolume, ValueTime } from "../../../pages/Growers/Grower/block_data_widget/constants";

// Define cookie keys
const COOKIE_KEYS = {
  measurementSystem: 'measurementSystem',
  depthVolume: 'depthVolume',
  valueTime: 'valueTime',
};

// Helper functions to get and set cookies
const getCookie = (key: string, defaultValue: string): string => {
  return Cookies.get(key) || defaultValue;
};

const setCookie = (key: string, value: string) => {
  Cookies.set(key, value, { expires: 365, sameSite: 'strict', secure: true }); // Expires in 1 year
};

// Initialize state with values from cookies or defaults
const initialState: User = {
  ownerId: '',
  sub: '',
  ownerAccountId: '',
  growerCognitoId: '',
  given_name: '',
  family_name: '',
  email: '',
  farmManagerId: '',
  httpErr: undefined,
  role: undefined,
  measurementSystem: getCookie(COOKIE_KEYS.measurementSystem, MeasurementSystem.Metric) as MeasurementSystem,
  depth_volume: Number(getCookie(COOKIE_KEYS.depthVolume, DepthVolume.depth.toString())) as DepthVolume,
  value_time: Number(getCookie(COOKIE_KEYS.valueTime, ValueTime.value.toString())) as ValueTime,
  country: '',
};

export const UserSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setMeasurementSys(state, action: PayloadAction<UserMeasurementSystem>) {
      state.measurementSystem = action.payload.measurementSystem;
      setCookie(COOKIE_KEYS.measurementSystem, action.payload.measurementSystem);
    },
    setDepthVolume(state, action: PayloadAction<UserDepthVolume>) {
      state.depth_volume = action.payload.depth_volume;
      setCookie(COOKIE_KEYS.depthVolume, action.payload.depth_volume.toString());
    },
    setValueTime(state, action: PayloadAction<UserValueTime>) {
      state.value_time = action.payload.value_time;
      setCookie(COOKIE_KEYS.valueTime, action.payload.value_time.toString());
    },
    // Optional: Action to reset preferences
  },
  extraReducers: (builder) => {
    builder.addCase(getUserDetails.fulfilled, (state, action) => {
      // Update user details except preferences
      state.ownerId = action.payload.ownerId;
      state.sub = action.payload.sub;
      state.ownerAccountId = action.payload.ownerAccountId;
      state.growerCognitoId = action.payload.growerCognitoId;
      state.given_name = action.payload.given_name;
      state.family_name = action.payload.family_name;
      state.email = action.payload.email;
      state.role = action.payload.role;
      state.country = action.payload.country;

      // **Do NOT** overwrite preferences from the server
      // Preferences are already loaded from cookies during initialState
    });

    builder.addCase(getUserDetails.rejected, (state, action) => {
      // Handle errors if needed
      state.httpErr = action.error;
    });

    builder.addCase(updateUserGrowerId.fulfilled, (state, action: PayloadAction<any>) => {
      state.growerCognitoId = action.payload;
      window.location.replace('/');
    });

    builder.addCase(updateUserGrowerId.rejected, (state, action) => {
      state.httpErr = action.payload;
    });
    },
});

export const { setMeasurementSys, setDepthVolume, setValueTime } = UserSlice.actions;

export const userReducer = UserSlice.reducer;

