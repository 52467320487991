import { ConversionActions, ConversionResult } from '../../../../../shared/measurements-converter/converter.types';
import { MeasurementSystem } from '../../../../../shared/data/types/UserTypes';
import { measurementSystemLabels } from '../../../../../shared/measurements-converter/converter.constants';
import { measurementSystemConvertValue } from '../../../../../shared/measurements-converter/converter';
import { ActualConsumption } from '../../../../../shared/data/types/blockDataTypes';
import { graphDataToGraphArgs } from './blockGraphHelper';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

import timezone from 'dayjs/plugin/timezone';

dayjs.extend(timezone);

const USER_TIMEZONE = dayjs.tz.guess(); // Automatically detect

export interface ActualIrrigation {
    id: string;
    blockId: number;
    start: string;      // Use string
    finish: string;     // Use string
    flow: number | string;
    created_at?: string; // Use string
}

export interface FormattedFlowData {
    date: string; // Format YYYY-MM-DD
    flow: number;
}

export function formatFlowData(
    flowData: ActualIrrigation[] | null | undefined,
    selectedMeasurementSystem: MeasurementSystem
): FormattedFlowData[] | null {
    if (!flowData || flowData.length === 0) {
        return null;
    }

    const flowByDate: { [key: string]: number } = {};

    flowData.forEach((irrigation) => {
        // Parse start date as a date-only value without timezone
        const start = dayjs(irrigation.start);
        const flow = parseFloat(irrigation.flow as string); // Ensure flow is converted to a number

        // Format the start date as 'YYYY-MM-DD'
        const dateKey = start.format('YYYY-MM-DD');

        // Convert flow based on the selected measurement system
        const convertedFlow = getCurrentETValObj(flow, selectedMeasurementSystem);

        if (convertedFlow && convertedFlow.value !== null) {
            // Initialize flowByDate[dateKey] if undefined
            if (flowByDate[dateKey] === undefined) {
                flowByDate[dateKey] = 0;
            }
            // Add the converted value to flowByDate
            flowByDate[dateKey] += convertedFlow.value ?? 0;
        }
    });

    const formattedFlowData: FormattedFlowData[] = Object.keys(flowByDate).map((date) => {
        return {
            date: date, // Use the date key as is
            flow: parseFloat(flowByDate[date].toFixed(4)),
        };
    });

    return formattedFlowData;
}

export function alignDataWithCombinedDates(
    consumptionData: { dates: string[], values: number[] },
    irrigationData: { dates: string[], values: number[] },
    maxTempData: { dates: string[], values: (number | string)[] },
    avgTempData: { dates: string[], values: (number | string)[] }
): {
    combinedDates: string[], // Full dates
    consumptionValues: number[],
    irrigationValues: number[],
    maxTempValues: (number | string)[],
    avgTempValues: (number | string)[]
} {
    // Step 1: Combine all unique dates from Actual Consumption, Actual Irrigation, and temp data
    const allDatesSet = new Set([...consumptionData.dates, ...irrigationData.dates, ...maxTempData.dates, ...avgTempData.dates]);
    const allDates = Array.from(allDatesSet).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

    // Step 2: Create arrays for aligned values for each dataset
    const alignedConsumptionValues: number[] = [];
    const alignedIrrigationValues: number[] = [];
    const alignedMaxTempValues: (number | string)[] = [];
    const alignedAvgTempValues: (number | string)[] = [];

    allDates.forEach((date) => {
        const consumptionIndex = consumptionData.dates.indexOf(date);
        const irrigationIndex = irrigationData.dates.indexOf(date);
        const maxTempIndex = maxTempData.dates.indexOf(date);
        const avgTempIndex = avgTempData.dates.indexOf(date);

        alignedConsumptionValues.push(consumptionIndex !== -1 ? consumptionData.values[consumptionIndex] : 0);
        alignedIrrigationValues.push(irrigationIndex !== -1 ? irrigationData.values[irrigationIndex] : 0);

        // Push empty string ('') if max_temp or avg_temp is missing or invalid
        alignedMaxTempValues.push(maxTempIndex !== -1 && maxTempData.values[maxTempIndex] !== null && !isNaN(Number(maxTempData.values[maxTempIndex])) ? maxTempData.values[maxTempIndex] : '');
        alignedAvgTempValues.push(avgTempIndex !== -1 && avgTempData.values[avgTempIndex] !== null && !isNaN(Number(avgTempData.values[avgTempIndex])) ? avgTempData.values[avgTempIndex] : '');
    });

    // Step 3: Return the combined dates and aligned values
    return {
        combinedDates: allDates,
        consumptionValues: alignedConsumptionValues,
        irrigationValues: alignedIrrigationValues,
        maxTempValues: alignedMaxTempValues,
        avgTempValues: alignedAvgTempValues,
    };
}

/**
 * Function to get the converted ET value based on the measurement system.
 */
export const getCurrentETValObj = (
    etVal: number | null,
    selectedMeasurementSystem: MeasurementSystem,
): ConversionResult => {
    let result: ConversionResult;
    if (selectedMeasurementSystem === MeasurementSystem.Metric) {
        result = {
            value: etVal,
            label: measurementSystemLabels.mm,
        };
    } else {
        result = measurementSystemConvertValue(
            etVal,
            selectedMeasurementSystem,
            ConversionActions.MillimeterAndInches,
        );
    }

    return result;
};

/**
 * Process block data to create unique date entries with consumption, avg_temp, and max_temp.
 */
export const processBlockData = (
    blockData: { actualConsumption: ActualConsumption[] },
    monthNames: string[],
    selectedMeasurementSystem: MeasurementSystem,
    getCurrentETValObj: (
        etVal: number | null,
        selectedMeasurementSystem: MeasurementSystem,
    ) => ConversionResult,
) => {
    const uniqueDateDataMap: {
        [key: string]: {
            consumption: number;
            avg_temp: number | string;
            max_temp: number | string;
        };
    } = {};

    blockData.actualConsumption.forEach((entry: ActualConsumption) => {
        // Parse the date as a date-only value without timezone
        const startDate = dayjs(entry.refDate.toString());
        const fullDate = startDate.format('YYYY-MM-DD');

        if (entry.ETa != null) {
            const consumptionValue = getCurrentETValObj(entry.ETa, selectedMeasurementSystem).value ?? 0;

            if (
                !uniqueDateDataMap[fullDate] ||
                (entry.avg_temp !== null &&
                    entry.max_temp !== null &&
                    consumptionValue > uniqueDateDataMap[fullDate].consumption)
            ) {
                uniqueDateDataMap[fullDate] = {
                    consumption: consumptionValue,
                    avg_temp: entry.avg_temp != null ? entry.avg_temp : '',
                    max_temp: entry.max_temp != null ? entry.max_temp : '',
                };
            }
        }
    });

    return uniqueDateDataMap;
};

/**
 * Extracts and formats the graph data from the processed date data map.
 */
export const extractGraphData = (
    uniqueDateDataMap: {
        [key: string]: {
            consumption: number;
            avg_temp: any;
            max_temp: any;
        };
    }
) => {
    const fullDates: string[] = Object.keys(uniqueDateDataMap).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

    const dates: string[] = fullDates; // Keep full dates

    const values: number[] = [];
    const avgTemp: any[] = [];
    const maxTemp: any[] = [];

    fullDates.forEach((date) => {
        const data = uniqueDateDataMap[date];
        values.push(data.consumption);
        avgTemp.push(data.avg_temp);
        maxTemp.push(data.max_temp);
    });

    return {
        dates: dates, // Do not reverse; maintain chronological order
        values: values,
        avg_temp: avgTemp,
        max_temp: maxTemp,
    };
};

/**
 * Prepares the final graph arguments using the data and aligned bar graph values.
 */
export const prepareGraphArgs = (
    graphData: any, // Actual Consumption data
    flowData: ActualIrrigation[] | null | undefined,  // Updated to accept null or undefined
    isToroAccount: boolean,
    startValueProp: any,
    endValueProp: any,
    selectedMeasurementSystem: MeasurementSystem,
    t: any
) => {
    // Format the flow data (Actual Irrigation data)
    const formattedFlowData: FormattedFlowData[] | null = flowData ? formatFlowData(flowData, selectedMeasurementSystem) : null;

    // If no flow data is returned, handle accordingly
    if (!formattedFlowData) {
        // Depending on your requirements, you can:
        // 1. Return null or a default graph argument
        // 2. Skip irrigation data processing
        // 3. Throw an error or log a warning

        // Example: Return graph args without irrigation data
        const graphArgsWithoutIrrigation = graphDataToGraphArgs(
            {
                dates: graphData.dates,           // Actual Consumption dates
                values: graphData.values,         // Actual Consumption values
                avg_temp: graphData.avg_temp,
                max_temp: graphData.max_temp,
            },
            {
                dates: [],                        // Empty irrigation dates
                values: [],                       // Empty irrigation values
            },
            isToroAccount,
            startValueProp,
            endValueProp,
            selectedMeasurementSystem,
            t
        );

        return graphArgsWithoutIrrigation;
    }

    // Extract dates and flow values from the formatted flow data
    const irrigationDates = formattedFlowData.map((entry) => entry.date);
    const irrigationValues = formattedFlowData.map((entry) => entry.flow);

    // Align all data, including max_temp and avg_temp
    const alignedData = alignDataWithCombinedDates(
        { dates: graphData.dates, values: graphData.values }, // Actual Consumption data
        { dates: irrigationDates, values: irrigationValues },   // Actual Irrigation data
        { dates: graphData.dates, values: graphData.max_temp }, // Max temperature data
        { dates: graphData.dates, values: graphData.avg_temp }  // Avg temperature data
    );

    // Prepare the graph arguments using the aligned data
    const graphArgs = graphDataToGraphArgs(
        {
            dates: alignedData.combinedDates,           // Combined list of all dates (full dates 'YYYY-MM-DD')
            values: alignedData.consumptionValues,      // Aligned Actual Consumption values
            avg_temp: alignedData.avgTempValues,        // Aligned avg_temp values
            max_temp: alignedData.maxTempValues,        // Aligned max_temp values
        },
        {
            dates: alignedData.combinedDates,           // Combined list of all dates (full dates 'YYYY-MM-DD')
            values: alignedData.irrigationValues        // Aligned Actual Irrigation values
        },
        isToroAccount,
        startValueProp,
        endValueProp,
        selectedMeasurementSystem,
        t
    );

    return graphArgs;
};