import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const useModalStyles = makeStyles((theme: Theme) => ({
    modalContainer: {
        position: 'relative',
        backgroundColor: theme.palette.surfaceVariant.main,
        borderRadius: theme.borderRadius.l,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        '@media (max-width:1200px)': {
            height: '80%', // Set modal height to 80% on mobile
        },
        '@media (max-width: 767px)': {
            height: '80%', // Set modal height to 80% on mobile
        },
    },
    modalWrapper: {
        '& .ant-modal': {
            height: '790px',
            '@media (max-width: 1200px)': {
                height: '90%',
                top: '10%',
            },
            '@media (max-width: 767px)': {
                height: '90%',
                top: '10%',
            },
        },
        '& .ant-modal-content': {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
        },
        '& .ant-modal-close': {
            // Add padding to the close button on mobile size
            '@media (max-width: 767px)': {
            },
        },
        '& .ant-modal-body': {
            flexGrow: 1,
            overflowY: 'auto',
            overflowX: 'hidden',
            height: '100%',
            '-webkit-overflow-scrolling': 'touch',
            '&::-webkit-scrollbar': {
                display: 'none',
            },
            '@media (max-width: 1200px)': {
                overflowY: 'auto',
            },
            '@media (max-width: 767px)': {
                overflowY: 'auto',
            },
        },
    },
    modalHeader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '@media (max-width: 767px)': {
            paddingTop: theme.paddings.l,
            ...theme.typography.title3,

        },
    },
    title:
    {
        ...theme.typography.title2,
        marginLeft: theme.paddings.s,
        '@media (max-width: 767px)': {
            ...theme.typography.title3,

        },
    },
    icon: {
        '@media (max-width: 767px)': {
            paddingTop: theme.paddings.m,
        },
    },
    separator: {
        height: '2px',
        backgroundColor: '#DFDFDF',
        width: '100%',
        margin: theme.paddings.m
    },
    buttonRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBlock: '16px',
    },
    dayPickerSelect: {
        width: '313px',
        '@media (max-width: 767px)': {
            width: '260px',
        },
    },
    selectDropdown: {
        '& .ant-select-item-option': {
            ...theme.typography.bodyRegular, // Ensure all options have bodyRegular typography
            color: theme.palette.onBorder.main, // Default color for unselected options

            '&.ant-select-item-option-selected': {
                color: theme.palette.primary.main, // Selected option text color inside dropdown
                backgroundColor: theme.palette.surfaceVariant.main,    // No background for selected option

                // Add this rule to retain primary color on hover for selected option
                '&:hover': {
                    color: theme.palette.primary.main, // Keep the primary color on hover for the selected option
                    backgroundColor: 'transparent',    // No background on hover
                },
            },
            '&:hover': {
                color: theme.palette.onSurface.main, // Hovered option text color
                backgroundColor: 'transparent',     // No background on hover
            },

        },
        '@media (max-width: 767px)': {
            width: '200px',
        },
    },
    selectBorder: {
        width: '313px',
        '&.ant-select .ant-select-selector': {
            borderColor: `${theme.palette.onBorder.main} !important`,
        },
        '&.ant-select-open .ant-select-selector': {
            borderColor: `${theme.palette.onBorder.main} !important`, // Keep the primary color when dropdown is open
        },
        '@media (max-width: 767px)': {
            width: '200px',
        },
    },
    exportButton: {
        ...theme.typography.bodyMedium,
        backgroundColor: theme.palette.surfaceWhite.main,
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        borderWidth: '1px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.paddings.xl,
        borderRadius: theme.borderRadius.xxl,
        cursor: 'pointer',
        width: '180px',
        gap: theme.paddings.s,
        '&:hover': {
            backgroundColor: theme.palette.surface.main + ' !important', // Override hover background
            color: theme.palette.primary.main + ' !important',                // Override hover text color
            borderColor: theme.palette.primary.main + ' !important',          // Override hover border color
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',                       // Add the desired shadow on hover
        },
        // Add this to force cursor style for the button and inner elements
        '& *': {
            cursor: 'pointer !important',
        },
        '@media (max-width: 767px)': {
            width: '100px',
            hight: '100px',
            paddingBlock: theme.paddings.l,
            borderRadius: '50%',
        },
    },

    //card section
    cardRow: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap', // Ensure cards can wrap if necessary

        '@media (max-width: 767px)': {
            flexDirection: 'column', // Cards will stack vertically on mobile size
            gap: theme.paddings.s,   // Add some spacing between cards in mobile view
        },
    },
    card: {
        width: '313px',
        height: '78px',
        padding: '20px',
        backgroundColor: theme.palette.surfaceWhite.main,
        borderRadius: theme.borderRadius.m,
        border: `1px solid ${theme.palette.surfaceVariant.main}`, // Border color and width
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        textAlign: 'left', // Align content on the left
        '@media (max-width: 767px)': {
            width: '100%', // Make the card take full width on mobile
            height: 'auto', // Allow height to adjust based on content
        },
    },
    cardTitle: {
        ...theme.typography.bodyMedium,
        color: theme.palette.primary.main,
    },
    cardSubtitle: {
        ...theme.typography.infoMedium,
        color: '#888888',
    },
    cardValue: {
        ...theme.typography.title1,
        color: theme.palette.onSurface.main,
        '@media (max-width: 767px)': {
            fontSize: '22px',
        },
    },
    //table section
    tableContainer: {
        flexGrow: 1,
        marginTop: '20px',
        maxHeight: '494px',
        overflowY: 'auto', // Enable vertical scrolling
        borderRadius: theme.borderRadius.m,
        border: '2px solid #DFDFDF', // Add border around the table container
        paddingRight: '8px', // Add right padding for scrollbar space
        overflowX: 'hidden', // Disable horizontal scrolling
    },
    table: {
        width: 'calc(100% - 8px)', // Subtract padding to fit within container
        borderCollapse: 'collapse',
        tableLayout: 'fixed', // Ensures columns maintain fixed width and space evenly
        '& td': {
            padding: '10px',
            textAlign: 'center', // Center the text in the table headers and cells
            borderBottom: '1px solid #ddd',
            wordWrap: 'break-word', // Ensures long text wraps and doesn't cause horizontal scroll
        },
        '& th': {
            padding: '10px',
            textAlign: 'center', // Center the text in the table headers and cells
            borderBottom: '1px solid #ddd',
            wordWrap: 'break-word', // Ensures long text wraps and doesn't cause horizontal scroll
            ...theme.typography.bodyMedium,
            position: 'sticky',
            top: 0,
            backgroundColor: theme.palette.surfaceVariant.main,
            zIndex: 1, // Ensures headers stay above the table rows
            height: '48px',
            '@media (max-width: 767px)': {
                fontSize: '12px',
                padding: '0px',
            },
        },
        '& tr': {
            ...theme.typography.bodyRegular,
            height: '64px',
            backgroundColor: theme.palette.surfaceWhite.main,
            '@media (max-width: 767px)': {
                fontSize: '12px',
            },
        },
    },
    // New class for date-time cells
    dateTimeCell: {
        '& span': {
            display: 'block',
        },
    },
    dateTimeTime: {
        ...theme.typography.bodySmallRegular,
        '@media (max-width: 767px)': {
            fontSize: '12px',
        },
    },
    dateTimeDate: {
        ...theme.typography.infoMedium,
        color: theme.palette.onBorder.main,
        '@media (max-width: 767px)': {
            fontSize: '12px',
        },
    },
}));
export default useModalStyles;