import fieldsIcon from 'src/assets/Icons/sharedIcons/fields-icon.svg';
import './fieldsIcon.css';

function FieldsIcon(): JSX.Element {
  return (
    <img
      src={fieldsIcon}
      alt=""
      style={{ paddingRight: '0.7rem' }}
      className="fields-icon"
    />
  );
}

export default FieldsIcon;
