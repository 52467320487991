import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles(
  (theme: Theme) => ({
    sideListContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      paddingInline: theme.paddings.s,
      backgroundColor: theme.palette.surfaceVariant.main,
      borderRadius: theme.borderRadius.m,
      overflow: 'hidden',
        '@media (max-width: 767px)': {
            backgroundColor: theme.palette.surfaceWhite.main,
            paddingInline: 0,

        },
    },
      farmTitleContainer: {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',  // Vertically center the grower title and button
          width: '100%',  // Ensure full width container
          paddingBlock: theme.paddings.s,
      },
     farmList: {
      flexShrink: 0, // Prevent shrinking
      maxWidth: '100%',
      '@media (max-width: 767px)': {
        position: 'sticky',
          top: 0,
        zIndex: 10,
      },
    },
    blockListContainer: {
      overflowY: 'auto', // Enable vertical scrolling
    },
    '@media (max-width: 767px)': {
      sideListContainer: {
        width: '100%',
        height: '100%',
      },
    },
      separator: {
          alignSelf: 'center',
          height: '1px',
          backgroundColor: theme.palette.surfaceMenu.main,
          marginTop: theme.paddings.l,
          marginBottom: theme.paddings.m,
          width: '98%',
          borderRadius: theme.borderRadius.xxxl,
      },
      addFarmButton: {
          paddingRight: theme.paddings.m,
      },
      growerTitle: {
          ...theme.typography.title3,
          color: theme.palette.onBorder.main,
          flexGrow: 1,  // Allow the title to take up available space
          display: 'flex',
          alignItems: 'center',  // Vertically align the text within its container
          '@media (min-width: 767.9px) and (max-height: 700px)': {
                fontSize: '15px',
            }
          },
      addFarmBtn: {
          display: 'flex',
          alignItems: 'center',  // Vertically center the button content
          justifyContent: 'center',  // Center the icon inside the button
          backgroundColor: 'transparent',
          color: theme.palette.accent.main,
          border: 'none',
          cursor: 'pointer',
          lineHeight: 'unset',
          height: 'unset',
          padding: '0 0.45rem',
          marginLeft: 'auto',  // Ensure it stays on the right side
      },
      titleContainer: {
          background: theme.palette.backgroundGrey.main,
          '@media (max-width: 767px)': {
              backgroundColor: theme.palette.surfaceWhite.main,
          },
      },
      farmContainer: {
          width: '100%',
          height: '100%',
          paddingInlineEnd: '0.5rem',
          paddingBlockStart: '0.3rem',
          flex: 1,
          '& p': {
              textAlign: 'left',
              margin: 0,
              opacity: 0.7,
              fontSize: 'calc(0.6rem + 0.4vw)',
              fontWeight: 700,
              lineHeight: 'normal',
              color: '#676879',
              WebkitUserSelect: 'none',
              WebkitTouchCallout: 'none',
              MozUserSelect: 'none',
              msUserSelect: 'none',
              userSelect: 'none',
          },
      },
    modalOverlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 1000,
    },
    searchBlockFarmContainer: {
      maxWidth: '100%',
      display: 'flex',
      borderRadius: '4px',
      justifyContent: 'space-between',
    },
    addBlockContainer: {
      width: '100%',
      height: '100%',
      paddingBlock: '0',
      '& p': {
        textAlign: 'left',
        margin: 0,
        opacity: 0.7,
        fontSize: 'calc(0.6rem + 0.4vw)',
        fontWeight: 700,
        lineHeight: 'normal',
        color: '#676879',
        marginBlock: '0',
        paddingInlineStart: '1rem',
        WebkitUserSelect: 'none',
        WebkitTouchCallout: 'none',
        MozUserSelect: 'none',
        msUserSelect: 'none',
        userSelect: 'none',
      },
    },
    addBlockBtn: {
      position: 'relative',
      backgroundColor: 'transparent',
      fontWeight: '600',
      color: theme.palette.accent.main,
      border: 'none',
      cursor: 'pointer',
      lineHeight: 'unset',
    },
      accordion: {
          width: '100%',
          boxShadow: 'none',
          paddingInline: theme.paddings.s,
          backgroundColor: theme.palette.surfaceWhite.main,
},
      accordionContent: {
          display: 'flex',
          flexDirection: 'column',
      },
      summaryText: {
          display: 'flex',
          flexDirection: 'column',
      },

      subheading: {
          fontSize: theme.typography.pxToRem(12),
          color: theme.palette.text.secondary,
      },
      selectedAccordion: {
          backgroundColor: theme.palette.action.selected,
      },
      summaryContent: {

      },
      accordionDetails: {
      },
      accordionSummary: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between', // Ensure content is spaced between left and right
          '& .MuiAccordionSummary-content': {
              margin: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between', // Chevron on the right
              width: '100%',
          },
      },
      summaryRow: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%', // Ensure it spans the full width
      },
      heading: {
          ...theme.typography.bodyRegular,
          flex: 1, // Take up available space
          textAlign: 'left',
      },
  }),
  { name: 'BlockListAction' },
);

export default useStyles;
