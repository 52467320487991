import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  searchAndSort: {
    width: '100%',
    paddingTop: theme.paddings.s,
    backgroundColor: 'transparent',
  },
  '@media (max-width: 767px)': {
    searchAndSort: {
      height: '40px',
    },
  },
  searchInput: {
    maxWidth: '-webkit-fill-available',
    flex: '1',
    color: theme.palette.oldPrimary.main,
  },
}));

export default useStyles;
