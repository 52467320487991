import React from 'react';
import sensorsIcon from 'src/assets/Icons/sharedIcons/sensors-icon.svg';
import './fieldsIcon.css';

function FieldsIcon(): JSX.Element {
  return (
      <img
          src={sensorsIcon}
          alt="Sensors Icon"
          className="fields-icon"
      />
  );
}

export default FieldsIcon;