// blockGraphHelper.ts
import * as echarts from 'echarts';
import { GraphTooltipParams } from '../../../../../shared/data/types/ETConsumptionGraphTypes';
import {
  ConversionActions,
  ConversionResult,
} from '../../../../../shared/measurements-converter/converter.types';
import { MeasurementSystem } from '../../../../../shared/data/types/UserTypes';
import { measurementSystemLabels } from '../../../../../shared/measurements-converter/converter.constants';
import { measurementSystemConvertValue } from '../../../../../shared/measurements-converter/converter';
import { roundPrecision } from '../../../../../shared/helpers/HelperFunctions';
import treeIcon from '../../../../../assets/Icons/blockData/green_tree_graph.svg';
import blueSprinkler from '../../../../../assets/Icons/blockData/blue_sprinkler.svg';
import dayjs from "dayjs";

export interface BlockGraphData {
  dates: string[]; // Full dates in 'YYYY-MM-DD' format
  values: number[];
  avg_temp: any[];
  max_temp: any[];
}

export function tooltipFormatter(
    measurementSystem: MeasurementSystem,
    params: GraphTooltipParams[],
    t: any,
): string {
  let consumptionContent = '';
  let irrigationContent = '';
  let axisLabel = '';

  // Helper function to format full date with translation using dayjs
  function formatFullDate(fullDate: string): string {
    const date = dayjs(fullDate);
    const day = date.date();
    const year = date.year();

    // Extract the full month name in English
    const englishMonthName = date.format('MMMM');

    // Translate the full month name
    const translatedFullMonth = t(englishMonthName);
    const translatedShortMonth = translatedFullMonth.slice(0, 3);

    return `${translatedShortMonth} ${day}, ${year}`;
  }

  params.forEach(param => {
    if (param.seriesName === t('blockDataWidget.actual_consumption') && isValidNumber(param.value)) {
      const consumptionConversion: ConversionResult = getCurrentETValObj(
          measurementSystem,
          param.value,
      );
      const roundedConsumption = roundPrecision(param.value, 2);

      consumptionContent =
          `<div style="display: flex; align-items: center; margin-top: 5px;">
                    <img src="${treeIcon}" alt="Actual Consumption" style="width: 20px; height: 20px; padding: unset; margin-right: 5px;" />
                    <span style="font-size: 0.9em; margin-right: 5px;">
                        <strong>${t('blockDataWidget.consumption')}</strong>
                    </span>
                    <span style="font-size: 0.9em;">
                        <strong>${roundedConsumption}</strong> ${consumptionConversion.label}
                    </span>
                </div>`;
    }

    if (param.seriesName === t('blockDataWidget.actual_irrigation') && isValidNumber(param.value)) {
      const irrigationConversion: ConversionResult = getCurrentETValObj(
          measurementSystem,
          param.value,
      );
      const roundedIrrigation = roundPrecision(param.value, 2);

      irrigationContent =
          `<div style="display: flex; align-items: center; margin-top: 5px;">
                    <img src="${blueSprinkler}" alt="Actual Irrigation" style="width: 20px; height: 20px; padding: unset; margin-right: 5px;" />
                    <span style="font-size: 0.9em; margin-right: 5px;">
                        <strong>${t('blockDataWidget.irrigation')}</strong>
                    </span>
                    <span style="font-size: 0.9em;">
                        <strong>${roundedIrrigation}</strong> ${irrigationConversion.label}
                    </span>
                </div>`;
    }

    // Capture the axis label from any param (assuming all params share the same axis value)
    if (!axisLabel && param.axisValue) {
      axisLabel = formatFullDate(param.axisValue);
    }
  });

  // Construct the final tooltip HTML
  const tooltipContent =
      `<div style="white-space: nowrap; margin-bottom: 5px;">
            ${consumptionContent}
            ${irrigationContent}
        </div>
        <div style="margin-top: 5px; font-size: 0.8em; color: #676879;">
            ${axisLabel}
        </div>`;

  return tooltipContent;
}

export function tempXAxisFormatter(
    measurementSystem: MeasurementSystem,
    value: any,
): string {
  const tempConversionResult: ConversionResult = getCurrentTempObj(
      measurementSystem,
      value,
  );
  const convertedTempObj = isValidNumber(value)
      ? tempConversionResult
      : { value: 0, label: '' };
  const roundedTempVal = roundPrecision(convertedTempObj.value, 0);
  return `${roundedTempVal}${convertedTempObj.label}`;
}

export function getCurrentTempObj(
    measurementSystem: MeasurementSystem,
    tempVal: number | undefined,
): ConversionResult {
  if (!tempVal) {
    return {
      value: tempVal,
      label: '‒',
    };
  }
  if (measurementSystem === MeasurementSystem.Metric) {
    return {
      value: tempVal,
      label: measurementSystemLabels.cel,
    };
  }
  return measurementSystemConvertValue(
      tempVal,
      measurementSystem,
      ConversionActions.CelsiusAndFahrenheit,
  );
}

export function isValidNumber(value: number | undefined): boolean {
  return (
      value !== undefined &&
      value !== null &&
      !Number.isNaN(value) &&
      value !== Infinity &&
      value !== -Infinity
  );
}

export function getCurrentETValObj(
    measurementSystem: MeasurementSystem,
    etVal: number | null,
): ConversionResult {
  if (measurementSystem === MeasurementSystem.Metric) {
    return {
      value: etVal,
      label: measurementSystemLabels.mm,
    };
  }
  return measurementSystemConvertValue(
      etVal,
      measurementSystem,
      ConversionActions.MillimeterAndInches,
  );
}

export function graphDataToGraphArgs(
    graphData: BlockGraphData,
    alignedBarGraphValues: any,
    isToroAccount: boolean,
    startValueProp: any,
    endValueProp: any,
    measurementSystem: MeasurementSystem,
    t: any, // Ensure t is passed as a parameter
): any {
  const hasIrrigationData = Array.isArray(alignedBarGraphValues.values) && alignedBarGraphValues.values.length > 0;
  const actual_consumption = t('blockDataWidget.actual_consumption');
  const actual_irrigation = t('blockDataWidget.actual_irrigation');
  const measurement = measurementSystem === MeasurementSystem.Metric ? 'mm' : 'in';
  const legendData = hasIrrigationData
      ? [actual_consumption, actual_irrigation]
      : [actual_consumption];

  return {
    grid: {
      top: 10,
      left: 50, // Increased from 10 to 50
      right: hasIrrigationData ? 40 : 55,
      bottom: 40,
      containLabel: true,
    },
    tooltip: {
      backgroundColor: '#fff',
      trigger: 'axis',
      axisPointer: {
        type: 'none',
      },
      padding: [5, 10],
      textStyle: {
        color: '#676879',
        fontFamily: 'Rubik, serif',
        align: 'left',
        rich: {
          bold: {
            fontWeight: 700,
            fontSize: '1.5rem',
          },
          smaller: {
            fontSize: 10,
          },
        },
      },
      extraCssText: 'text-align: left',
      formatter: (params: any) => {
        return tooltipFormatter(measurementSystem, params, t);
      },
    },
    legend: {
      data: legendData,
      bottom: '0%',
      left: '0%',
    },
    xAxis: [
      {
        type: 'category',
        data: graphData.dates, // Full dates 'YYYY-MM-DD'
        boundaryGap: false,
        axisTick: {
          alignWithLabel: true,
        },
        splitLine: {
          show: false,
        },
        axisLabel: {
          hideOverlap: false,
          fontSize: 11,
          fontFamily: 'Rubik, sans-serif',
          formatter: (value: string) => {
            const date = dayjs(value);
            const day = date.date();

            // Get the full English month name
            const englishMonthName = date.format('MMMM');

            // Translate the full month name
            const translatedFullMonth = t(englishMonthName);

            // Get the first three characters for the abbreviated month
            const translatedShortMonth = translatedFullMonth.slice(0, 3);

            return `${translatedShortMonth}-${day}`;
          },
          showLastLabel: true, // Add this line to the primary X-axis
        },
      },
      {
        type: 'category',
        data: graphData.max_temp,
        position: 'top',
        axisLine: {
          show: false,
        },
        axisLabel: {
          formatter: (value: any) => (value !== '' ? tempXAxisFormatter(measurementSystem, value) : ''),
          margin: 15,
          hideOverlap: false,
          fontSize: 11,
          fontFamily: 'Rubik, sans-serif',
        },
        showLastLabel: true, // Ensure this is also applied if needed
        boundaryGap: false,
        axisTick: {
          alignWithLabel: true,
          show: false,
        },
        interval: 1,
        splitLine: {
          show: false,
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
        name: `${actual_consumption} (${measurement})`,
        min: 0,
        position: 'left',
        axisLabel: {
          formatter: (value: number) => `${value}`,
          fontSize: 11,
          margin: 50,
          fontFamily: 'Rubik, sans-serif',
          color: isToroAccount ? '#999999' : '#61CE70',
          align: 'left',
        },
        nameLocation: 'middle',
        nameGap: 60,
        nameTextStyle: {
          fontSize: 11,
          fontFamily: 'Rubik, sans-serif',
          color: isToroAccount ? '#999999' : '#61CE70',
          align: 'center',
          verticalAlign: 'middle',
        },
        splitLine: {
          show: false,
        },
      },
      {
        type: 'value',
        name: `${actual_irrigation} (${measurement})`,
        min: 0,
        position: 'right',
        axisLabel: {
          formatter: (value: number) => `${value}`,
          fontSize: 11,
          margin: 50,
          fontFamily: 'Rubik, sans-serif',
          color: '#79AEDF',
          align: 'right',
        },
        nameLocation: 'middle',
        nameGap: 60,
        nameTextStyle: {
          fontSize: 11,
          fontFamily: 'Rubik, sans-serif',
          color: '#79AEDF',
          align: 'center',
          verticalAlign: 'middle',
          rotate: 180,
        },
        splitLine: {
          show: false,
        },
      },
    ],
    dataZoom: {
      type: 'inside',
      xAxisIndex: [0, 1],
      filterMode: 'filter',
      zoomOnMouseWheel: true,
      startValue: graphData.dates.length - startValueProp,
      endValue: graphData.dates.length - endValueProp,
      minValueSpan: 15,
    },
    series: [
      {
        name: actual_consumption,
        type: 'line',
        data: graphData.values,
        yAxisIndex: 0,
        sampling: 'average',
        smooth: 0.4,
        showSymbol: false,
        symbol: 'circle',
        symbolSize: 8,
        connectNulls: true,
        animationDurationUpdate: (idx: number) => {
          return idx * 5;
        },
        animationEasingUpdate: 'linear',
        itemStyle: {
          color: isToroAccount ? '#999999' : '#61CE70',
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: isToroAccount ? '#999999' : '#61CE70',
            },
            {
              offset: 1,
              color: '#fff',
            },
          ]),
        },
        z: 1,
      },
      hasIrrigationData && {
        name: actual_irrigation,
        type: 'bar',
        data: alignedBarGraphValues.values,
        yAxisIndex: 1,
        xAxisIndex: 0,
        barMaxWidth: 30, // Adjust based on your design
        itemStyle: {
          color: '#79AEDF',
        },
        z: 2,
      },
    ].filter(Boolean), // Filter out the irrigation series if not present
  };
}