export const Regex = {
  EMAIL_REGEX: /^[^\s@]+@[a-z0-9-]+(?:\.[a-z]{2,7})+$/i,
} as const;

export const minZoomStep = 3;
export const middleZoomStep = 7;
export const yearlyZoomStep = 14;
export const minItems = 14;
export const maxItems = 365;

// width break points

// Extreme Small Devices, Phones
export const minWidth = 320;
export const xSmallMedWidth = 455; // Devices with max width of 575px
export const xSmallMaxWidth = 575; // Devices with max width of 575px

// Small Devices, Phones
export const smallMinWidth = 576; // Devices with min width of 576px
export const smallMaxWidth = 767; // Devices with max width of 767px

// Medium Devices, Tablets
export const mediumMinWidth = 768; // Devices with min width of 768px
export const mediumWidth = 850; // Devices with min width of 850px
export const mediumExtraWidth = 880.99; // Devices with min width of 880.99px
export const mediumMaxWidth = 991; // Devices with max width of 991px

// Large Devices, Desktops
export const largeMinWidth = 992; // Devices with min width of 992px
export const largeMediumWidth = 1080; // Devices with min width of 1080px
export const largeMaxWidth = 1199; // Devices with max width of 1199px

// Extra Large Devices, Large Desktops
export const xLargeMinWidth = 1200; // Devices with min width of 1200px
export const xLargeMediumWidth = 1350; // Devices with min width of 1350px
export const xLargeMaxWidth = 1439; // Devices with max width of 1439px

// 2X Large Devices, Wider Screens
export const xxLargeMinWidth = 1440; // Devices with min width of 1440px
export const xxLargeMaxWidth = 1919; // Devices with max width of 1919px

export const xSmallMaxHeight = 700; // Devices with max width of 575px

export const defaultLocale = 'en-US';

export const minBlockAgeToDisplayGraph = 10;
