// src/reduxToolkit/slices/FlowDataSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { actualIrrigationState, ActualIrrigation } from '../../../shared/data/types/blockDataTypes';
import {getUnitRealtimeIrrigation} from "../../thunks/flow_thunks";

const initialState: actualIrrigationState = {
    flow_data: {},
    realtime_irrigation: [], // Array of IMEIs as strings
};

export const FlowDataSlice = createSlice({
    name: 'flow-data',
    initialState,
    reducers: {
        setFlowData(state, action: PayloadAction<ActualIrrigation[]>) {
            const blockId = action.payload[0]?.block_id;
            if (blockId !== undefined) {
                state.flow_data[blockId] = action.payload;
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getUnitRealtimeIrrigation.fulfilled, (state, action: PayloadAction<string[]>) => {
            // Directly set the array of IMEIs
            state.realtime_irrigation = action.payload;
        });
        builder.addCase(getUnitRealtimeIrrigation.rejected, (state, action) => {
            // Handle errors as needed
            console.error('Failed to fetch real-time irrigation data:', action.payload);
        });
    },
});

export const { setFlowData } = FlowDataSlice.actions;

export const flowDataReducer = FlowDataSlice.reducer;