// src/pages/Growers/Growers.tsx

import { useParams, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';

// Redux
import { useAppDispatch, useAppSelector } from 'src/reduxToolkit/store/hooks';
import getGrowerFarms from 'src/reduxToolkit/thunks/grower_farm_thunks';
import { setCurrentGrower } from '../../reduxToolkit/slices/GrowerFarmsSlice/GrowerFarms.slice';

// Components
import Grower from './Grower/Grower';
import ProgressBar from '../../shared/components/ProgressBar';

// Types
import { MiniGrower } from '../../shared/data/types/growerFarmsTypes';

// Utilities
import { asNumber } from '../../utils/numbering';
import {getUnitRealtimeIrrigation} from "../../reduxToolkit/thunks/flow_thunks";

function Growers(): JSX.Element {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const routeParams = useParams<{ id?: string }>();

  const growerId = asNumber(routeParams.id);
  const farmId = asNumber(searchParams.get('farmId'));
  const blockId = asNumber(searchParams.get('blockId'));

  const grower: MiniGrower | null = useAppSelector((state) => {
    return state.rootReducer.GrowerFarmsState.currentGrower;
  });

  async function fetchGrowerFarms(id: number) {
    // Check if the grower is already in the state
    if (grower && grower.id === id) return;

    try {
      dispatch(setCurrentGrower(null));
      const response = await dispatch(getGrowerFarms(id)).unwrap();
      dispatch(setCurrentGrower(response.grower));

      // After setting the grower, extract IMEIs with isFlowSensor = true
      const imeisWithFlowSensor: string[] = [];
      response.grower.farms.forEach((farm) => {
        farm.blocks.forEach((block) => {
          block.units.forEach((unit) => {
            if (unit && unit.isFlowSensor) {
              imeisWithFlowSensor.push(unit.imei);
            }
          });
        });
      });

      if (imeisWithFlowSensor.length > 0) {
        // Dispatch getUnitRealtimeIrrigation with IMEIs
        await dispatch(getUnitRealtimeIrrigation(imeisWithFlowSensor));
      }

    } catch (error) {
      console.error('Failed to fetch grower farms:', error);
    }
  }

  useEffect(() => {
    if (growerId) {
      fetchGrowerFarms(growerId);
    }
  }, [growerId]);

  return grower ? (
      <Grower grower={grower} farmId={farmId} blockId={blockId} />
  ) : (
      <ProgressBar />
  );
}

export default Growers;