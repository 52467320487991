// AdditionalEmailsField.tsx
import React, { FC } from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { Regex } from 'src/shared/constants/consts';
import { isValidTLD } from 'src/shared/helpers/FormValidationHelper';

interface AdditionalEmailsFieldProps {
    additionalEmails: string[];
    setAdditionalEmails: React.Dispatch<React.SetStateAction<string[]>>;
}

const AdditionalEmailsField: FC<AdditionalEmailsFieldProps> = ({ additionalEmails, setAdditionalEmails }) => {
    const { t } = useTranslation();

    // Simple email validation for each tag
    const validateEmails = (emails: string[]): string[] => {
        return emails.filter((email) => Regex.EMAIL_REGEX.test(email) && isValidTLD(email));
    };

    return (
        <div className="AdditionalEmails">
            <Select
                className="additional-emails-select"
                mode="tags"
                style={{ width: '100%' }}
                placeholder={t('growerFieldModal.addEmailAddresses')}
                value={additionalEmails}
                onChange={(values) => {
                    const validated = validateEmails(values);
                    setAdditionalEmails(validated);
                }}
            />
        </div>
    );
};

export default AdditionalEmailsField;