import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  container: {
    paddingBlock: '2rem',
    minWidth: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    maxWidth: '900px',
    '& div:nth-child(4)': {
      '& span:nth-child(1)': {
        gap: '5px !important',
      },
    },
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    alignItems: 'center',
    width: '100%',
    paddingInline: '1.2rem',
    '& input': {
      border: '1px solid',
      borderColor: '#76778673 !important',
      borderRadius: '4px',
      height: '32px',
      padding: '0 11px',
      '&::placeholder': {
        color: '#76778673 !important',
      },
      '&:-ms-input-placeholder': {
        color: '#76778673 !important',
      },
      '&::-ms-input-placeholder': {
        color: '#76778673 !important',
      },
      '& .ant-form-item .ant-form-item-control-input': {
        border: '1px solid #76778673',
        '&:focus, &:hover': {
          boxShadow: '0 0 3px #76778673 !important',
          border: 'inherit',
        },
        '&:focus, &:active, &:hover': {
          border: '1px solid #76778673 !important',
        },
      },
    },
    '& input:focus-visible': {
      outline: 'none',
    },
  },
  formRow: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  checkboxContainer: {
    alignSelf: 'flex-start',
  },
  singleInputRow: {
    alignSelf: 'flex-start',
    width: 'calc(50% - 7.5px)',
    display: 'flex',
    flexDirection: 'column',
    '& .ant-select:not(.ant-select-customize-input) .ant-select-selector': {
      width: '100%',
    },
    '& button, input': {
      width: '100%',
    },
  },
  twoInputsInARow: {
    display: 'flex',
    gap: '15px',
    width: '100%',
    '@media (max-width: 767px)': {
      width: '100%',
      flexDirection: 'column',
      '& .ant-select:not(.ant-select-customize-input) .ant-select-selector': {
        width: '100%',
      },
      '& button, input': {
        width: '100%',
      },
    },
  },
}));

export default useStyles;
