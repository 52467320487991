import { useCallback, useEffect, useState } from 'react';
import { setSelectedWeek } from 'src/reduxToolkit/slices/WeatherSlice/weather.slice';
import { getWeeklyWeatherData } from 'src/reduxToolkit/thunks/weather_thunks';
import Box from '@mui/material/Box';
import closeIcon from 'src/assets/Icons/sharedIcons/xIcon.svg';
import { Button } from 'antd';
import { useAppDispatch, useAppSelector } from 'src/reduxToolkit/store/hooks';
import { NavbarState } from 'src/shared/constants/enums';
import { WeatherStatus } from 'src/shared/data/types/weatherTypes';
import { useLocation, useSearchParams } from 'react-router-dom';
import { OpenWeatherBar } from 'src/shared/data/WeatherData';
import WeatherOpenState from './WeatherOpenState';
import WeatherDropdownList from './WeatherList/WeatherDropdownList';
import WeatherClosedState from './WeatherClosedState';
import { useStyles } from './useStyles';

function Weather(props: WeatherStatus): JSX.Element {
  const {
    isOpenProp,
    handleClickFunction,
    onClose,
    currTempIcon,
    currentTemperatureValue,
    currentTemperatureLabel,
    currentFarm,
  } = props;
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();
  const weeklyData = useAppSelector(
    (state) => state.rootReducer.weatherState.weeklyData,
  );
  const dispatch = useAppDispatch();
  const [startIdx, setStartIdx] = useState(
    OpenWeatherBar.SECOND_WEEK_START_IDX,
  ); // IDX = 6
  const [endIdx, setEndIdx] = useState(OpenWeatherBar.SECOND_WEEK_END_IDX); // IDX = 13
  const [disableCounter, setDisableCounter] = useState(
    OpenWeatherBar.FIRST_WEEK_START_IDX,
  ); // IDX = 0
  const [startDateInDateRange, setStartDateInDateRange] = useState(
    weeklyData[OpenWeatherBar.SECOND_WEEK_START_IDX]?.date,
  ); // IDX = 6
  const [endDateInDateRange, setEndDateInDateRange] = useState(
    weeklyData[OpenWeatherBar.SECOND_WEEK_END_IDX - 1]?.date,
  ); // IDX = 12
  // Conditional rendering check for startDateRange and endDateRange
  const isDateRangeDefined = startDateInDateRange && endDateInDateRange;
  const [searchParams] = useSearchParams();
  const inSensorWidget: boolean = !!searchParams.get('sensors');

  useEffect(() => {
    if (currentFarm != null) {
      void dispatch(
        getWeeklyWeatherData({
          farmId: currentFarm.id ?? 0,
          lat: currentFarm.centerLat,
          lng: currentFarm.centerLng,
          timeZone: currentFarm.timezone ?? '',
        }),
      );
    }
  }, [dispatch, currentFarm]);

  useEffect(() => {
    if (handleClickFunction) handleClickFunction(isOpen);
  }, [handleClickFunction, isOpen, isOpenProp]);

  const handleOnClick = useCallback((): void => {
    setIsOpen(!isOpen);
  }, [isOpen, setIsOpen]);

  const handleOnNextDate = useCallback(
    (e) => {
      e.stopPropagation();
      let counter: number = disableCounter;
      if (currentFarm) {
        if (disableCounter < 2) {
          counter += 1;
          setDisableCounter(counter);
        }

        if (startIdx + 7 < weeklyData.length - 1 && startIdx !== 0) {
          // startIdx + 7 = 13 and weeklyData.length - 1 = 20 - range 13-20. meaning that if start date is not 20 but is not 0 we are in the default first week range
          setStartIdx(OpenWeatherBar.SECOND_WEEK_END_IDX); // Idx = 13
          setEndIdx(weeklyData.length);
          setStartDateInDateRange(
            weeklyData[OpenWeatherBar.THIRD_WEEK_START_IDX]?.date,
          ); // Idx = 13
          setEndDateInDateRange(weeklyData[weeklyData.length - 1]?.date);
        } else if (startIdx === OpenWeatherBar.FIRST_WEEK_START_IDX) {
          // if this is the first week in range. idx = 0
          setStartIdx(OpenWeatherBar.SECOND_WEEK_START_IDX); // idx = 6
          setEndIdx(OpenWeatherBar.SECOND_WEEK_END_IDX); // idx = 13
          setStartDateInDateRange(
            weeklyData[OpenWeatherBar.SECOND_WEEK_START_IDX]?.date,
          ); // idx = 6
          setEndDateInDateRange(
            weeklyData[OpenWeatherBar.SECOND_WEEK_END_IDX - 1]?.date,
          ); // idx = 12
        }
      }
    },
    [currentFarm, startIdx, disableCounter, weeklyData],
  );

  const handleOnPrevDate = useCallback(
    (e) => {
      e.stopPropagation();
      let counter: number = disableCounter;
      if (currentFarm) {
        if (disableCounter > -2) {
          counter -= 1;
          setDisableCounter(counter);
        }
        if (startIdx - 7 > 0 && startIdx !== 6) {
          setStartIdx(OpenWeatherBar.SECOND_WEEK_START_IDX); // idx = 6
          setEndIdx(OpenWeatherBar.SECOND_WEEK_END_IDX); // idx = 13
          setStartDateInDateRange(
            weeklyData[OpenWeatherBar.SECOND_WEEK_START_IDX]?.date,
          ); // idx = 6
          setEndDateInDateRange(
            weeklyData[OpenWeatherBar.SECOND_WEEK_END_IDX]?.date,
          ); // idx = 12
        } else if (startIdx === OpenWeatherBar.SECOND_WEEK_START_IDX) {
          // if we see the second week which is our default week.
          setStartIdx(OpenWeatherBar.FIRST_WEEK_START_IDX); // idx = 0
          setEndIdx(OpenWeatherBar.FIRST_WEEK_END_IDX); // idx = 6
          setStartDateInDateRange(
            weeklyData[OpenWeatherBar.FIRST_WEEK_START_IDX]?.date,
          ); // idx = 0
          setEndDateInDateRange(
            weeklyData[OpenWeatherBar.FIRST_WEEK_END_IDX - 1]?.date,
          ); // idx = 5
        }
      }
    },
    [currentFarm, startIdx, disableCounter, weeklyData],
  );

  useEffect(() => {
    if (weeklyData) {
      dispatch(setSelectedWeek(weeklyData.slice(startIdx, endIdx)));
      setStartDateInDateRange(weeklyData[startIdx]?.date);
      setEndDateInDateRange(weeklyData[endIdx - 1]?.date);
    }
  }, [dispatch, weeklyData, startIdx, endIdx]);

  return (
    <div className={classes.root}>
      <Box
        className={
          location.pathname === NavbarState.FIELDS
            ? isOpen
              ? classes.boxActive
              : classes.box
            : classes.sensorBox
        }>
        {!inSensorWidget && (
            <Button
                className={`${classes.closeIcon} ${isOpen ? classes.closeIconOpen : ''}`}
                onClick={(e) => {
                  e.stopPropagation();
                  if (onClose) {
                    onClose(true);
                  }
                }}
            >
              <img src={closeIcon} alt="Close" />
            </Button>
        )}
        {isOpen ? (
          <WeatherOpenState
            handleOnClick={handleOnClick}
            handleOnPrevDate={handleOnPrevDate}
            handleOnNextDate={handleOnNextDate}
            startDateRange={startDateInDateRange}
            endDateRange={endDateInDateRange}
            disableCounter={disableCounter}
            isDateRangeDefined={isDateRangeDefined}
          />
        ) : (
          <WeatherClosedState
            handleOnClick={handleOnClick}
            currTempIcon={currTempIcon}
            currentTemperatureValue={currentTemperatureValue ?? 0} // default value is 0
            currentTemperatureLabel={currentTemperatureLabel ?? ''}
          />
        )}
      </Box>
      {currentFarm && (
        <WeatherDropdownList isOpen={isOpen} currentFarm={currentFarm} />
      )}
    </div>
  );
}

export default Weather;
